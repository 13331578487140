import { I18n } from "react-redux-i18n";
import * as yup from "yup";

const isIdentificationNumPeselSchema = {
  identificationNumber: yup.string().when("isIdentificationNumPesel", {
    is: true,
    then: yup
      .string()
      .required(I18n.t("Forms.snackbar.required"))
      .matches(/^\d+$/, I18n.t("Forms.snackbar.peselNumber"))
      .length(11, I18n.t("Forms.snackbar.peselLength")),
  }),
};

export const DriverLicenceExamineeSchema = {
  drivingLicenseIssuer: yup.string().when("drivingLicenseIds", (drivingLicenseIds: number[]) =>
  ((drivingLicenseIds?.length ?? 0) > 0
    ? yup.string().required()
    : yup.string().optional())
  ),
  drivingLicenseIds: yup.array().of(yup.number()).when("drivingLicenseIssuer", (drivingLicenseIssuer: string) =>
  ((drivingLicenseIssuer?.length ?? 0) > 0
    ? yup.array().required()
    : yup.array().optional())
  ),
};

export const RequiredExamineeSchema = {
  sex: yup.string().required(),
  directory: yup.number().required(),
  birthDate: yup.date().required(),
};

export const NonAnonymousExamineeSchema = {
  ...RequiredExamineeSchema,
  ...DriverLicenceExamineeSchema,
  ...isIdentificationNumPeselSchema,
  firstName: yup.string().matches(/\S/).required(),
  lastName: yup.string().matches(/\S/).required(),
};

export const DeanonymizingExamineeSchema = {
  ...RequiredExamineeSchema,
  ...DriverLicenceExamineeSchema,
  ...isIdentificationNumPeselSchema,
  firstName: yup.string().when("isIdentificationNumPesel", {
    is: true,
    then: yup.string().required(),
    otherwise: yup
      .string()
      .when("lastName", (lastName: string) =>
      ((lastName?.length ?? 0) > 0
        ? yup.string().matches(/\S/).required()
        : yup.string().optional())
      ),
  }),
  lastName: yup.string().when("isIdentificationNumPesel", {
    is: true,
    then: yup.string().required(),
    otherwise: yup
      .string()
      .when("firstName", (firstName: string) =>
      ((firstName?.length ?? 0) > 0
        ? yup.string().matches(/\S/).required()
        : yup.string().optional())
      ),
  }),
};

const commonPsychologicalConsultationOpinion = {
  firstName: yup.string().matches(/\S/).required(),
  lastName: yup.string().matches(/\S/).required(),
  country: yup.string().required(),
  address: yup.string().required(),
  city: yup.string().required(),
  postalCode: yup.string().required(),
  issueCity: yup.string().required(),
  issueDate: yup.string().required(),
  judgment: yup.string().required(),
  sex: yup.string().required(),
  completionDate: yup.string(),
};

const commonPsychologicalConsultationOpinionAnonymous = {
  issueCity: yup.string().required(),
  issueDate: yup.string().required(),
  judgment: yup.string().required(),
  sex: yup.string().required(),
  completionDate: yup.string(),
};

export const schemaPsychologicalConsultationOpinionAnonymous = yup.object({
  ...commonPsychologicalConsultationOpinionAnonymous,
});

export const schemaPsychologicalConsultationOpinionPESEL = yup.object({
  ...commonPsychologicalConsultationOpinion,
  pesel: yup.string().required(),
});

export const schemaPsychologicalConsultationOpinionWithoutPESEL = yup.object({
  ...commonPsychologicalConsultationOpinion,
  documentType: yup.string().required(),
  documentNumber: yup.string().required(),
});

const commonPsychologicalCard = {
  firstName: yup.string().matches(/\S/).required(),
  lastName: yup.string().matches(/\S/).required(),
  country: yup.string().required(),
  address: yup.string().required(),
  city: yup.string().required(),
  postalCode: yup.string().required(),
  issueCity: yup.string().required(),
  issueDate: yup.string().required(),
  judgment: yup.string().required(),
  sex: yup.string().required(),
  completionDate: yup.string(),
  education: yup.string().required(),
};

const commonPsychologicalCardAnonymous = {
  issueCity: yup.string().required(),
  issueDate: yup.string().required(),
  judgment: yup.string().required(),
  sex: yup.string().required(),
  completionDate: yup.string(),
};

export const schemaPsychologicalCardAnonymous = yup.object({
  ...commonPsychologicalCardAnonymous,
  age: yup.string().required(),
});

export const schemaPsychologicalCardPESEL = yup.object({
  ...commonPsychologicalCard,
  pesel: yup.string().required(),
  age: yup.string().required(),
});

export const schemaPsychologicalCardWithoutPESEL = yup.object({
  ...commonPsychologicalCard,
  documentType: yup.string().required(),
  documentNumber: yup.string().required(),
  age: yup.string().required(),
});

export const schemaPsychologicalLab = yup.object({
  name: yup.string().required(),
  address: yup.string(),
  cin: yup.string(),
  tin: yup.string(),
  licenseId: yup.string(),
  city: yup.string(),
  postalCode: yup.string(),
  licenseEndDate: yup.string(),
  theme: yup.string().required(),
  dateExpire: yup.date().nullable().default(undefined).required(),
  isActive: yup.boolean().required()
});

export const schemaPsychologicalLabUser = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  lang: yup.string().required(),
  email: yup.string().required(),
  isActive: yup.boolean().required(),
  isStaff: yup.boolean().required(),
});

export const schemaPsychologicalLabLicence = yup.object({
  id: yup.number(),
  dateExpire: yup.date().nullable().default(undefined).required(),
});

