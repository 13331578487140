/* eslint-disable camelcase */
export const ru = {
  HelpDocs: {
    contractTitle: "Контракты",
    contractName1: "Психологический контракт 2024",
    contractName2: "Психологический контракт RU 2024",
    contractName3: "Психологический контракт UA 2024",
    contractName4: "Политика защиты персональных данных - Информационная оговорка 2024",
    handbookTitle: "Справочник и каталог тестов",
    handbookName1: "Характеристика областей диагностики",
    handbookName2: "Психомоторные тесты",
    handbookName3: "Когнитивные тесты",
    handbookName4: "Тесты личности",
    handbookName5: "Каталог тестов 03-2024",
    modulesTitle: "Модули интервью",
    modulesName1: "Состояние здоровья",
    modulesName2: "Социальная и семейная ситуация",
    modulesName3: "Наблюдение",
    modulesName4: "Детективы защита оружие MTD I",
    modulesName5: "Лицензия MTD II",
    modulesName6: "Медицина труда MTD III",
    modulesName7: "Служебные водители MTD III",
    modulesName8: "Работа на высоте MTD III",
    modulesName9: "Погрузчики MTD III",
    modulesName10: "Транспортная психология управление транспортными средствами MTD IV"
  },
  Format: {
    ViewDateTime: "DD.MM.YYYY HH:mm",
    FormDateFormat: "D MMMM YYYY",
    ExamineeViewDateFormat: "DD.MM.YYYY",
  },
  Buttons: {
    accept: "Согласен",
    addExaminee: "Добавить обследуемого",
    addPsychLab: "Добавить аккаунт клиента",
    addPsychUser: "Добавить аккаунт пользователя",
    addUser: "Добавить аккаунт пользователя",
    addDirectory: "Добавить папку",
    assign: "Назначить",
    alreadyAssigned: "Назначено",
    assignExamination: "Назначить обследование",
    alreadyAssignedExamination: "Обследование в процессе",
    back: "Назад",
    backToLogin: "Вернуться на страницу входа",
    begin: "Начать",
    cancel: "Отменить",
    change: "Изменить",
    clear: "Очистить",
    close: "Закрыть",
    confirm: "Подтвердить",
    continue: "Далее",
    createExamination: "Назначить обследование",
    delete: "Удалить",
    deleteExaminee: "Удалить обследуемого",
    deletePsychLab: "Удалить аккаунт клиента",
    deletePsychLabLic: "Удалить лицензию",
    downloadAll: "Скачать все",
    edit: "Редактировать",
    editExaminee: "Редактировать обследуемого",
    editPsychLab: "Редактировать аккаунт клиента",
    editPsychLabLic: "Редактировать лицензию",
    addPsychLabLic: "Добавить лицензию",
    editPsychLabUser: "Редактировать аккаунт пользователя",
    examinationWithNoLawBase: "Обследование без правового основания",
    examinationWithLawBase: "Обследование с правовым основанием",
    exit: "Выход",
    generate: "Сгенерировать",
    generateAndPrint: "Сгенерировать и распечатать",
    go: "Перейти",
    goToDashboard: "Перейти в панель пользователя",
    login: "Войти",
    loginPsychLab: "Войти в аккаунт клиента",
    move: "Переместить",
    moveExaminee: "Переместить обследуемого",
    moveExaminees: "Переместить обследуемых",
    ok: "Ок",
    print: "Печать",
    printContract: "Распечатать контракт",
    save: "Сохранить",
    savePassword: "Сохранить пароль",
    savePin: "Сохранить ПИН",
    saveAndPrint: "Сохранить и распечатать",
    saveSettings: "Сохранить настройки",
    setPin: "Установить ПИН",
    search: "Поиск",
    setPassword: "Установить пароль",
    setNewPassword: "Установить новый пароль",
    sendLink: "Отправить ссылку",
    skipTests: "Пропустить тесты",
    understood: "Понятно",
    showActivities: "Показать",
    exportActivities: "Экспорт в файл XLS",
  },
  Common: {
    menu: {
      contact: "Помощь",
      dashboard: "Панель пользователя",
      folders: "Записи обследуемых",
      search: "Поиск",
      logout: "Выйти",
      settings: "Настройки",
      psychlabs: "Записи клиентов",
      breadcrumbsPsychLab: "Записи клиентов",
    },
    seo: {
      addExaminee: "Добавить обследуемого",
      addPsychLabLic: "Добавить лицензию",
      addPsychLab: "Добавить аккаунт клиента",
      dashboard: "Панель пользователя",
      editExaminee: "Редактировать обследуемого",
      helpdesk: "Помощь",
      helpdeskDocuments: "Документы помощи",
      judgement: "Заключение",
      login: "Вход",
      onboarding: "Онбординг",
      report: "Отчет",
      resetPassword: "Сброс пароля",
      setNewPassword: "Установка нового пароля",
      search: "Поиск",
      folders: "Картотека обследуемых",
      settings: "Настройки",
      examinationAssignment: "Назначение обследования с правовым основанием",
      examinationCard: "Карта Обследования"
    },
    warningPopup: {
      text: "Внесенные вами изменения не будут сохранены",
      header: "Вы уверены, что хотите выйти?",
      textSave: "Обязательные поля могут также находиться на других вкладках",
      headerSave: "Пожалуйста, заполните все обязательные поля!",
      promptLogout: "Вы будете автоматически выйдете из системы через PROMPT_TIME_MINUTES минуту из-за отсутствия активности."
    },
    logAdminAsUserPopup: {
      text: "Не удалось войти в аккаунт клиента",
      header: "Ошибка"
    },
    deleteExamineePopup: {
      text: "После удаления обследуемого восстановление данных будет невозможно",
      header: "Вы уверены, что хотите удалить обследуемого?"
    },
  },
  Contact: {
    header: "Техническая поддержка",
  },
  Cookies: {
    header: "Разрешение на использование cookies",
    consent: "Этот сайт использует cookies для обеспечения наилучшей работы этого приложения. Если вы согласны на хранение информации в cookies, нажмите кнопку СОГЛАСЕН. Если вы не согласны, вы не сможете использовать приложение и выполнять назначенные процедуры."
  },
  Dashboard: {
    header: "Недавно добавленные люди",
    footer: {
      lastLogin: "Последний вход",
      lastFailedLogin: "Последний неудачный вход",
      passwordExpired: "Срок действия вашего пароля истекает через",
      day: "день",
      days: "дней"
    },
    emptyState: {
      header: "Нет недавно добавленных обследуемых",
      info: "Чтобы увидеть список обследуемых, добавьте хотя бы одного."
    },
    kebabMenu: {
      print: "Распечатать контракт",
      profile: "Перейти к профилю обследуемого"
    }
  },
  AdminDashboard: {
    header: "Картотека клиентов",
    emptyState: {
      header: "Учетные записи клиентов еще не добавлены",
      info: "Чтобы увидеть список клиентов, добавьте хотя бы одну учетную запись."
    },
    emptyStateUsers: {
      header: "Учетные записи пользователей еще не добавлены",
      info: "Чтобы увидеть список пользователей, добавьте хотя бы одну учетную запись."
    },
    emptyStateLicense: {
      header: "Лицензии еще не добавлены",
      info: "Чтобы увидеть лицензию, добавьте её."
    },
    kebabMenu: {
      print: "Распечатать контракт",
      profile: "Перейти к профилю обследуемого"
    },
    PsychologicalLabsList: {
      labels: {
        name: "Название клиента",
        clientId: "ID клиента",
        status: "Статус",
        psychCnt: "Количество пользователей",
        createDate: "Дата создания",
        expireDate: "Срок действия учетной записи",
        details: "Подробности",
        usersCnt: "Количество пользователей",
        examineesCnt: "Количество обследуемых",
        examinationsCnt: "Количество обследований",
        taskPerPage: "Строк на странице:",
        all: "все",
        of: "из",
        moreThen: "больше чем"
      },
      labStatus: {
        enabled: "активный",
        disabled: "неактивный"
      }
    },
    Licence: {
      labels: {
        methodologyList: "Доступные методики",
        testsList: "Доступные тесты",
        licenseDate: "Дата выдачи лицензии",
        expireDate: "Срок действия лицензии"
      },
      deletePopup: {
        headerText: "Вы уверены, что хотите удалить лицензию?",
        contentText: "После удаления лицензии будет невозможно назначать обследования",
        confirmationText: "Да, я хочу удалить лицензию"
      }
    },
    PsychLab: {
      deletePopup: {
        headerText: "Вы уверены, что хотите удалить учетную запись клиента?",
        contentText: "После удаления учетной записи клиента будет невозможно восстановить данные",
        confirmationText: "Да, я хочу удалить учетную запись клиента"
      }
    },
    StatsList: {
      statFileName: "статистика_обследований.xlsx"
    },
    ActivitiesList: {
      labels: {
        date: "Дата",
        action: "Действие",
        executor: "Исполнитель",
        context: "Описание",
        status: "Статус",
        errorMsg: "Описание ошибки"
      },
      labStatus: {
        success: "успешно",
        error: "ошибка"
      }
    },
    UsersList: {
      deletePopup: {
        headerText: "Вы уверены, что хотите удалить учетную запись пользователя?",
        contentText: "После удаления учетной записи пользователя её невозможно будет восстановить",
        confirmationText: "Да, я хочу удалить учетную запись пользователя"
      },
      labels: {
        firstName: "Имя",
        lastName: "Фамилия",
        email: "Email",
        examineeCnt: "Количество участников",
        examinationCnt: "Количество обследований",
        isActive: "Статус",
        isStaff: "Роль"
      },
      labStatus: {
        enabled: "активный",
        disabled: "неактивный",
        adminRole: "администратор",
        superUserRole: "супер администратор",
        userRole: "пользователь",
        userBaseRole: "регистратор"
      },
      toolTip: {
        edit: "редактировать",
        delete: "удалить"
      }
    },
    PsychLabManagment: {
      breadcrumbs: {
        add: "Добавить учетную запись клиента",
        edit: "Редактировать учетную запись клиента",
        addLicense: "Добавить лицензию",
        editLicense: "Редактировать лицензию",
        addUser: "Добавить учетную запись пользователя",
        editUser: "Редактировать учетную запись пользователя",
        dashboard: "Панель пользователя",
        psychLabs: "Картотека клиентов"
      },
      header: {
        add: "Добавить учетную запись клиента",
        edit: "Редактировать учетную запись клиента",
        addUser: "Добавить учетную запись пользователя",
        editUser: "Редактировать учетную запись пользователя",
        addLicense: "Добавить лицензию",
        editLicense: "Редактировать лицензию"
      },
      PsychLabView: {
        labels: {
          details: "Подробности",
          download: "Скачать",
          edit: "Редактировать",
          generate: "Сгенерировать",
          name: "Название теста",
          nameFile: "Имя файла",
          print: "Печать",
          result: "Результат",
          type: "Тип документа"
        },
        tabs: {
          psychLabData: "Данные клиента",
          license: "Лицензия",
          users: "Пользователи",
          activityLogs: "Журнал действий",
          activityLogsFile: "журнал_действий",
          activityId: "id",
          activityDate: "дата",
          activityAction: "название действия",
          activityStatus: "статус",
          activityContext: "описание",
          activityExecutor: "исполнитель",
          stats: "Статистика",
          theme: "Внешний вид"
        }
      }
    }
  },
  ExaminationAssignmentView: {
    header: "Назначить обследование",
    labels: {
      chooseExaminationType: "Выберите тип обследования",
      chooseLawBase: "Выберите правовое основание обследования",
      description: "Описание",
      legalBase: "Правовое основание обследования",
      reason: "Цель обследования",
      suggestedTestMethods: "ПРЕДЛАГАЕМЫЕ МЕТОДИКИ",
      methodologyTest2Safe: "TEST2SAFE - методика психологического тестирования для работников охраны и детективов",
      methodologyTest2Drive: "TEST2DRIVE - методика проведения обследований водителей в рамках транспортной психологии",
      methodologyTest2Arms: "TEST2ARMS - методика психологического тестирования, лицензируемая деятельность",
      methodologyTest2Work: "TEST2WORK - методика психологического тестирования в медицине труда"
    },
    popup: {
      header: "Некоторые тесты, рекомендованные для выбранной методики, были отключены.",
      content: "Вы уверены, что хотите пропустить эти тесты?",
      checkbox: "Запомнить выбор для всех обследуемых"
    },
    license: "Тест недоступен в приобретенной лицензии"
  },
  PsychologicalTests: {
    psychomotor: "Психомоторные тесты",
    cognitive: "Когнитивные тесты",
    personality: "Личностные тесты"
  },
  ExamineeList: {
    labels: {
      contract: "Контракт",
      examine: "Обследование",
      examineLastDate: "Дата последнего обследования",
      allFinishExam: "Назначено / завершено",
      firstName: "Имя",
      folder: "Папка",
      lastName: "Фамилия",
      peselSlashIdentifier: "PESEL / Идентификатор",
      profile: "Просмотр профиля",
      added: "Добавлено"

    },
  },
  ExaminationList: {
    withoutLegalBasis: "Обследование без правового основания",
    labels: {
      assign: "Назначено",
      assignDate: "Дата назначения",
      create: "Создано",
      createDate: "Дата создания",
      contract: "Контракт",
      documentation: "Документация",
      end: "Завершено",
      endDate: "Дата завершения",
      interview: "Интервью",
      identificator: "ID обследования",
      language: "Язык",
      nameArchiveExamination: "Название батареи",
      nameExamination: "Название обследования",
      start: "Начато",
      startDate: "Дата начала"
    },
    kebabMenu: {
      delete: "Удалить обследование"
    },
    deletePopup: {
      headerText: "Вы уверены, что хотите удалить обследование?",
      contentText: "После удаления обследования его невозможно будет восстановить",
      confirmationText: "Да, я хочу удалить обследование"
    },
    toolTip: {
      contract: "Сгенерировать контракт",
      interview: "Интервью",
      documentation: "Документация",
      delete: "Удалить"
    },
  },
  ArchiveExaminationList: {
    emptyState: {
      info: "Нет архивных обследований, назначенных этому участнику",
    },
  },
  ExaminationView: {
    header: "Документация",
    breadcrumbs: {
      archive: "Архивные обследования"
    },
    details: {
      createDate: "Создание обследования",
      endDate: "Завершение обследования",
      identificator: "ID обследования",
      language: "Язык",
      name: "Название обследования",
      startDate: "Дата начала обследования",
      type: "Название батареи"
    },
    protocols: {
      language: "Язык протоколов",
      testProtocol: "Протокол теста"
    },
    labels: {
      details: "Подробности",
      download: "Скачать",
      edit: "Редактировать",
      generate: "Сгенерировать",
      name: "Название теста",
      nameFile: "Имя файла",
      print: "Печать",
      result: "Результат",
      type: "Тип документа"
    },
    tabs: {
      documentation: "Документация",
      protocols: "Протоколы",
      reports: "Отчеты"
    },
    common: {
      unavailableInLanguageOption: "Недоступно в выбранной языковой версии."
    },
    aimsKind: {
      aimKind_1: "Психологическое заключение - детективы",
      aimKind_3: "Заключение - работник физической охраны",
      aimKind_8: "Апелляционное заключение - работник физической охраны",
      aimKind_9: "Заключение - разрешение на оружие",
      aimKind_10: "Заключение - лицензированная деятельность военного или полицейского назначения",
      aimKind_11: "Заключение - лицензированная деятельность гражданского назначения",
      aimKind_12: "Апелляционное заключение - лицензированная деятельность гражданского назначения",
      aimKind_13: "Заключение - медицина труда",
      aimKind_14: "Заключение - медицина труда горное дело и горноспасательные работы",
      aimKind_15: "Заключение - медицина труда лицензия машиниста",
      aimKind_16: "Заключение - водитель категории C1, C1+E, C, C+E, D1, D1+E, D и D+E или управление трамваем",
      aimKind_17: "Заключение - восстановление водительских прав категории AM, A1, A2, A, B1, B, B+E и T",
      aimKind_18: "Заключение - восстановление водительских прав психологические противопоказания",
      aimKind_19: "Заключение - продление прав категории C1, C1+E, C, C+E, D1, D1+E, D и D+E или управление трамваем",
      aimKind_20: "Заключение - послеаварийное обследование водителя",
      aimKind_21: "Заключение - обследование водителя после употребления алкоголя или подобных веществ",
      aimKind_22: "Заключение - обследование водителя после превышения 24 баллов",
      aimKind_23: "Заключение - обследование водителя после не менее 2 нарушений",
      aimKind_24: "Заключение - обследование водителя психологические противопоказания",
      aimKind_25: "Заключение - водитель спецтранспорта",
      aimKind_26: "Заключение - послеаварийное обследование водителя (направление старосты)",
      aimKind_27: "Заключение - выполнение обязанностей инструктора",
      aimKind_28: "Заключение - выполнение обязанностей экзаменатора",
      aimKind_29: "Заключение - водитель-учащийся или кандидат в среднюю школу категории C или C+E",
      aimKind_30: "Заключение - водитель-учащийся или кандидат на профессиональный курс категории C или C+E",
      aimKind_31: "Заключение - профессиональный водитель по направлению работодателя",
      aimKind_32: "Заключение - самозанятый профессиональный водитель",
    },
  },
  ExamineeView: {
    anonymity: "Анонимный субъект",
    userData: {
      defaultAnonFirstName: "Субъект",
      defaultAnonLastName: "Анонимный",
      pesel: "Личный номер",
      sex: "Пол",
      birthDate: "Дата рождения",
      address: "Адрес",
      education: "Образование",
      drivingLicense: "Категория водительских прав",
      identityCard: "Документ, удостоверяющий личность",
      serialNumber: "Серия и номер",
      identificator: "Идентификатор",
      drivingLicenseIssuer: "Орган, выдавший водительские права",
      examineName: "Фамилия, имя, отчество",
    },
    drivingLicense: {
      am: "AM",
      a1: "A1",
      a2: "A2",
      a: "A",
      b1: "B1",
      b: "B",
      be: "B+E",
      c: "C",
      c1: "C1",
      c1e: "C1+E",
      ce: "C+E",
      d: "D",
      d1: "D1",
      d1e: "D1+E",
      de: "D+E",
      t: "T",
      tramvay: "ТРАМВАЙ",
    },
    tabs: {
      archive: "Архивные обследования",
      data: "Данные субъекта",
      examination: "Обследования",
    },
    kebabMenu: {
      edit: "Редактировать данные субъекта",
      delete: "Удалить субъекта",
    },
    emptyState: {
      header: "Нет обследований, назначенных этому субъекту",
      info: "Чтобы увидеть список обследований, назначьте хотя бы одно обследование.",
    },
    translations: {
      passport: "паспорт",
      id_card: "удостоверение личности",
      male: "мужчина",
      female: "женщина",
      primary: "начальное образование",
      lowerSecondary: "неполное среднее образование",
      basicVocational: "начальное профессиональное образование",
      basicIndustry: "базовое профессионально-техническое образование",
      secondaryIndustry: "среднее профессионально-техническое образование",
      secondary: "среднее образование",
      higher: "высшее образование",
    },
  },
  ExamineeManagment: {
    breadcrumbs: {
      add: "Добавить субъекта",
      edit: "Редактировать субъекта",
      dashboard: "Панель пользователя",
      directories: "Картотека субъектов",
    },
    header: {
      add: "Добавить нового субъекта",
      edit: "Редактировать данные субъекта",
    },
  },
  Forms: {
    adminFields: {
      methodologyTlt: "Методология",
      testPsychMotorTlt: "психомоторные тесты",
      testCogTlt: "когнитивные тесты",
      testPersonTlt: "личностные тесты",
      address: "Улица и номер дома",
      name: "Название",
      firstName: "Имя",
      lastName: "Фамилия",
      email: "Электронная почта",
      password: "Пароль",
      pin: "ПИН-код",
      isActive: "Статус учетной записи",
      isStaff: "Роль",
      dateExpire: "Срок действия учетной записи",
      dateLicExpire: "Срок действия лицензии",
      licMethodologies: "Доступные методологии",
      licTests: "Доступные тесты",
      city: "Город",
      postalCode: "почтовый индекс",
      clientId: "ID клиента",
      cin: "Регистрационный номер",
      tin: "ИНН",
      theme: "Оформление",
      examineesCnt: "Количество субъектов",
      usersCnt: "Количество пользователей",
      dateFrom: "период с",
      dateTo: "период по",
      activityName: "действие",
      activityDetails: "Подробности",
      status: "статус",
    },
    adminOptions: {
      theme: {
        optimisTheme: "optimis",
        defaultTheme: "стандартная",
      },
      status: {
        active: "активный",
        disabled: "неактивный",
      },
      admin: {
        admin: "администратор",
        user: "пользователь",
        baseuser: "регистратор",
      },
    },
    adminSnackbar: {
      required: "Необходимо заполнить все обязательные поля.",
      email_unique_error: "Данный адрес электронной почты уже существует в базе данных!",
      email_wrong_error: "Указанный адрес электронной почты недействителен!",
    },
    fields: {
      address: "Улица и номер дома",
      additionalInfo: "Дополнительная информация",
      appealAuthority: "Название учреждения",
      appealDeadline: "Дата",
      birthDate: "Дата рождения",
      city: "Город",
      country: "Страна",
      diffrentIdentificationNumber: "Другой идентификатор",
      directory: "Папка",
      documentDate: "Дата выдачи",
      drivingLicense: "Категория водительских прав",
      drivingLicenseIssuer: "Орган выдачи водительских прав",
      education: "Образование",
      identificationNumber: "Личный номер",
      identityDocumentType: "Документ, удостоверяющий личность",
      identityDocumentNumber: "Серия и номер",
      interval: "Выберите доверительный интервал",
      judgmentNumber: "Номер решения",
      languageReport: "Выберите язык отчета",
      legalBasis: "Правовое основание обследования",
      months: "месяцы",
      name: "Имя",
      nextExamination: "Дата следующего обследования",
      postalCode: "Почтовый индекс",
      recipient: "Выберите получателя",
      sex: "Пол",
      standard: "Выберите стандарт",
      surname: "Фамилия",
      years: "Лет",
      year: "Год",
      examineeRegistryId: "Номер в реестре обследуемых",
      age: "Возраст",
      date: "Дата",
      institutionName: "Название учреждения",
      lang: "Язык",
    },
    options: {
      activitiesStatus: {
        ok: "ок",
        user_error: "ошибка пользователя",
        server_error: "ошибка сервера",
      },
      lang: {
        polish: "Польский",
        english: "Английский",
        espaniol: "Испанский",
        mexico: "Мексиканский испанский",
        russian: "Русский",
        ukrain: "Украинский",
      },
      activities: {
        ALL: "все",
        AUDIT_ERROR: "Ошибка журнала активности",
        ALL_ITEMS: "Все действия",
        APP_REGISTRATION: "Регистрация приложения",
        TEST_RESULT_SUBMITTED: "Результаты теста отправлены",
        CONSOLE_PIN_VERIFICATION: "Проверка ПИН-кода на консоли пользователя",
        SAVE_AGREEMENTS: "Сохранение согласий на обследование и обработку данных",
        TEST_SKIPPED: "Тест пропущен на консоли",
        EXAMINATION_STARTED: "Обследование начато",
        EXAMINATION_TERMINATED: "Обследование завершено",
        ADMIN_LOGGED_OUT: "Администратор вышел из системы",
        ADMIN_CHANGE_LAB_ID: "Администратор вошел в учетную запись клиента",
        USER_LOGGED_IN: "Пользователь вошел в систему",
        USER_LOGGED_OUT: "Пользователь вышел из системы",
        PASSWORD_CHANGE_REQUEST: "Запрос на смену пароля",
        PASSWORD_CHANGE: "Смена пароля",
        PASSWORD_CHANGE_EXPIRED: "Смена пароля после истечения срока действия",
        PIN_CHANGE: "Смена ПИН-кода",
        DIRECTORY_CREATED: "Папка создана",
        DIRECTORY_NAME_CHANGED: "Название папки изменено",
        DIRECTORY_DELETED: "Папка удалена",
        EXAMINEE_ADDED: "Субъект добавлен",
        EXAMINEE_DATA_CHANGE: "Данные субъекта изменены",
        EXAMINEE_DELETED: "Данные субъекта удалены",
        EXAMINEE_MOVED_TO_DIFFERENT_DIRECTORY: "Данные субъекта перемещены в другую папку",
        EXAMINATION_ASSIGNED: "Обследование назначено",
        EXAMINATION_DELETED: "Обследование удалено",
        EXAMINATION_REPORT_DOWNLOADED: "Документы отчета скачаны",
        CONTRACT_DOWNLOADED: "Контракт скачан",
        EXAMINATION_PROTOCOL_DOWNLOADED: "Протокол обследования скачан",
        EXAMINATION_PROTOCOLS_ZIP_DOWNLOADED: "Протоколы обследования ZIP скачаны",
        INTERVIEW_CHANGED: "Интервью изменено",
        INTERVIEW_DOWNLOADED: "Интервью скачано",
        ADMIN_LICENCE_ADDED: "Добавлена лицензия для учетной записи клиента",
        ADMIN_LICENCE_CHANGED: "Изменена лицензия учетной записи клиента",
        ADMIN_LICENCE_DELETED: "Удалена лицензия учетной записи клиента",
        ADMIN_PSYCHOLOGICAL_LAB_ADDED: "Добавлена учетная запись клиента",
        ADMIN_PSYCHOLOGICAL_LAB_DELETED: "Удалена учетная запись клиента",
        ADMIN_PSYCHOLOGICAL_LAB_CHANGED: "Изменены данные учетной записи клиента",
        ADMIN_USER_ADDED: "Добавлен пользователь к учетной записи клиента",
        ADMIN_USER_DELETED: "Удален пользователь из учетной записи клиента",
        ADMIN_USER_CHANGED: "Изменены данные пользователя учетной записи клиента",
        ADMIN_LOGGED_IN: "Администратор вошел в систему",
      },
      norms: {
        norm_1: "OPT_1.0.PL",
        norm_2: "OPT_1.0.UA",
        norm_3: "OPT_1.0.RU",
      },
      bool: {
        true: "Да",
        false: "Нет",
      },
      education: {
        primary: "Начальное",
        lowerSecondary: "Неполное среднее",
        secondary: "Среднее",
        basicVocational: "Начальное профессиональное",
        basicIndustry: "Базовое профессионально-техническое",
        secondaryIndustry: "Среднее профессионально-техническое",
        higher: "Высшее",
      },
      identityDocument: {
        idCard: "Удостоверение личности",
        passport: "Паспорт",
      },
      sex: {
        female: "Женщина",
        male: "Мужчина",
      },
      country: {
        poland: "Polska",
        austria: "Austria",
        bulgaria: "Bułgaria",
        croatia: "Chorwacja",
        cyprus: "Cypr",
        czechRepublic: "Republika Czeska",
        denmark: "Dania",
        estonia: "Estonia",
        finland: "Finlandia",
        france: "Francja",
        greece: "Grecja",
        spain: "Hiszpania",
        netherlands: "Holandia",
        ireland: "Irlandia",
        lithuania: "Litwa",
        luxembourg: "Luksemburg",
        latvia: "Łotwa",
        malta: "Malta",
        germany: "Niemcy",
        portugal: "Portugalia",
        romania: "Rumunia",
        slovakia: "Słowacja",
        slovenia: "Słowenia",
        sweden: "Szwecja",
        hungary: "Węgry",
        italy: "Włochy",
        afghanistan: "Afganistan",
        albania: "Albania",
        angola: "Angola",
        saudiArabia: "Arabia Saudyjska",
        argentina: "Argentyna",
        armenia: "Armenia",
        azerbaijan: "Azerbejdżan",
        bangladesh: "Bangladesz",
        belarus: "Białoruś",
        bolivia: "Boliwia",
        bosniaAndHerzegovina: "Bośnia i Hercegowina",
        brazil: "Brazylia",
        chile: "Chile",
        china: "Chiny",
        chad: "Chad",
        montenegro: "Czarnogóra",
        democraticRepublicOfTheCongo: "Demokratyczna Republika Konga",
        dominicanRepublic: "Republika Dominikany",
        ecuador: "Ekwador",
        ethiopia: "Etiopia",
        gambia: "Gambia",
        ghana: "Ghana",
        georgia: "Gruzja",
        guatemala: "Gwatemala",
        honduras: "Honduras",
        india: "Indie",
        indonesia: "Indonezja",
        iran: "Iran",
        iceland: "Islandia",
        israel: "Izrael",
        jamaica: "Jamajka",
        japan: "Japonia",
        yemen: "Jemen",
        jordan: "Jordania",
        cambodia: "Kambodża",
        cameroon: "Kamerun",
        qatar: "Katar",
        kazakhstan: "Kazachstan",
        congo: "Kongo",
        southKorea: "Korea Południowa",
        laos: "Laos",
        northernMacedonia: "Północna Macedonia",
        madagascar: "Madagaskar",
        malaysia: "Malezja",
        mali: "Mali",
        mauritania: "Mauritania",
        mexico: "Meksyk",
        myanmar: "Myanmar",
        moldova: "Moldova",
        mozambique: "Mozambik",
        namibia: "Namibia",
        niger: "Niger",
        nigeria: "Nigeria",
        nicaragua: "Nikaragua",
        norway: "Norwegia",
        oman: "Oman",
        panama: "Panama",
        paraguay: "Paragwaj",
        peru: "Peru",
        centralAfricanRepublic: "Republika Środkowoafrykańska",
        russia: "Rosja",
        rwanda: "Rwanda",
        elSalvador: "Salwador",
        senegal: "Senegal",
        serbia: "Serbia",
        somalia: "Somali",
        unitedStatesOfAmerica: "Stany Zjednoczone Ameryki",
        sudan: "Sudan",
        southSudan: "Południowy Sudan",
        switzerland: "Szwajcaria",
        tajikistan: "Tadżykistan",
        thailand: "Tajlandia",
        turkey: "Turcja",
        uganda: "Uganda",
        ukraine: "Ukraina",
        uruguay: "Urugwaj",
        venezuela: "Wenezuela",
        unitedKingdom: "Zjednoczone Królestwo",
        vietnam: "Wietnam",
        zambia: "Zambia",
        unitedArabEmirates: "Zjednoczone Emiraty Arabskie",
        zimbabwe: "Zimbabwe",
      },
      recipient: {
        examinee: "Обследуемый",
        psychologist: "Психолог",
        specialist: "Специалист",
      },
      languagesDocumentation: {
        pl: "польский",
        en: "английский",
      },
    },
    extraFields: "Дополнительные данные",
    requiredFields: "Обязательные данные",
    anonymous: "Анонимное обследование",
    snackbar: {
      peselLength: "Личный номер должен состоять из 11 цифр",
      peselNumber: "Личный номер должен содержать только цифры",
      required: "Необходимо заполнить все обязательные поля.",
    },
  },
  FormValidation: {
    max: "Название не может превышать 128 символов",
    required: "Это поле обязательно для заполнения",
    Pin: {
      length: "Должен содержать 4 цифры",
    },
    Password: {
      length: "Минимум 8 символов",
      characters: "Должен содержать заглавную букву, строчную букву, специальный символ и цифру",
    },
  },
  Helpdesk: {
    header: "Помощь",
    subheader: "Служба поддержки",
    phoneNo: "тел.",
    mobile: "моб.",
    help: "Удаленная поддержка",
    contact: "Контакты",
    documents: {
      header: "Справочные документы",
      labels: {
        download: "Скачать",
        name: "Название документа",
      },
    },
  },
  Interview: {
    header: "Интервью",
    inputAdditionalInformation: "Введите дополнительную информацию",
    inputHoursOfWork: "Введите количество рабочих часов за последние сутки",
    years: "Лет",
    months: "Месяцев",
    hours: "Часов",
    tabs: {
      currentPsychophysicalState: "Состояние здоровья",
      socialAndFamilySituation: "Социальная и семейная ситуация",
      observation: "Наблюдение",
      drivingVehicles: "Управление транспортными средствами",
      occupationalMedicine: "Профессиональная медицина",
      securityAndDetectives: "Охранные и детективные услуги",
      licensedActivities: "Лицензированная деятельность",
    },
    questions: {
      questionAnswers: {
        yes: "да",
        no: "нет",
      },
      questionTexts: {
        when: "Когда?",
        sinceWhen: "С какого времени?",
        forHowLong: "До какого времени?",
        why: "По какой причине?",
        whatAreTheConsequences: "Какие были последствия для здоровья?",
        describeTheCircumStancesOfUsage: "Опишите обстоятельства использования",
      },
      methodologies: {
        currentPsychophysicalState: {
          headers: {
            currentState: "Текущее психофизическое состояние",
            criticalEvents: "Критические события",
          },
          questionExtendedTexts: {
            commonText: "Опишите обстоятельства этого события/ситуации",
          },
          questionTexts: {
            question1: "Употреблял ли обследуемый алкоголь в течение последних 48 часов?",
            question2: "Работал ли обследуемый профессионально в течение последней или предпоследней ночи?",
            question3: "Количество рабочих часов за последние сутки",
            question4: "Травма головы, требующая медицинской помощи",
            question5: "Потеря сознания",
            question6: "Пребывание в больнице",
            question7: "Было ли когда-либо или есть в настоящее время лечение/был ли когда-либо диагноз следующих заболеваний:",
            question7a: "a. заболевания пищеварительной системы (язва желудка или двенадцатиперстной кишки, нарушения функции кишечника и т.д.)",
            question7b: "b. заболевания сердца и системы кровообращения (ишемическая болезнь, повышенное артериальное давление)",
            question7c: "c. нарушения слуха или зрения (за исключением ношения корректирующих очков)",
            question7d: "d. диабет",
            question7e: "e. эпилепсия",
            question7f: "f. психические заболевания (депрессия, невроз, психиатрическое лечение (уточнить о психиатрическом лечении близкого члена семьи)",
            question8: "Регулярный прием лекарств (уточнить, знает ли о побочных эффектах и спросить о других лекарствах, влияющих на психические функции)",
            question9: "Проблемы со сном",
            question10: "Обращение за помощью к:",
            question10a: "a. психологу",
            question10b: "b. психиатру",
            question10c: "c. неврологу",
            question11: "Справка об инвалидности",
            question12: "Проблемы, связанные с употреблением алкоголя",
            question13: "Пребывание в вытрезвителе",
            question14: "Употребление одурманивающих/психоактивных веществ/наркотиков",
            question15: "Предложения врача или близкого человека обратиться за лечением из-за нервозности, рассеянности или проблем с памятью или ориентацией",
          },
        },
        socialAndFamilySituation: {
          questionTexts: {
            question1: "Серьезный конфликт или проблема, связанная с работой " +
              "(например, несчастный случай на работе, выговор, трудовой суд, дисциплинарное увольнение, моббинг)",
            question2: "Проблемы в период обучения " +
              "(например, повторение класса, исключение из школы, приостановление прав учащегося)",
            question3: "Серьезный семейный конфликт или проблемы в личных ситуациях " +
              "(например, судебные дела, участие в драках, можно уточнить о семейном положении, детях)",
            question4: "Ситуация прямой угрозы жизни (например, пожар, наводнение и другие катастрофы)",
            question5: "Опыт физического насилия в отношении себя (например, нападение, кража, избиение)",
            question6: "Участие в общественных организациях (например, волонтерство)",
            question7: "Судимость (например, получение штрафов, приговор суда)",
          },
        },
        observation: {
          questionTexts: {
            question1: "Контакт с собеседником",
            question2: "Язык и риторика",
            question3: "Настроение и отношение",
            question4: "Самообладание и контроль поведения и эмоций",
          },
          questionAnswers: {
            question1a: "Устанавливает очень хороший контакт с собеседником; поддерживает зрительный контакт; " +
              "ведет себя уверенно и естественно, не позволяет вывести себя из равновесия или проявить нетерпение, " +
              "ведет себя спонтанно и естественно.",
            question1b: "Устанавливает контакт с собеседником; поддерживает зрительный контакт; ведет себя естественно, " +
              "хотя заметны признаки некоторого волнения.",
            question1c: "Ведет себя отстраненно, избегает зрительного контакта; ведет себя неестественно, " +
              "малые раздражители вызывают потерю равновесия, видны явные признаки нервозности и нетерпения.",
            question2a: "Формулирует высказывания, используя правильный язык, " +
              "строит четкие, понятные предложения, поддерживает логическую структуру речи, " +
              "использует богатый словарный запас.",
            question2b: "Формулирует высказывания правильно, " +
              "в целом поддерживает их логическую структуру; словарный запас средний, но правильный.",
            question2c: "Формулирует короткие, формальные высказывания, часто бессвязные или неясные; " +
              "использует неправильные фразы и формулировки; предложения очень простые, " +
              "словарный запас очень бедный, встречаются грамматические ошибки.",
            question3a: "Наблюдается очень веселое, оптимистичное настроение; удовлетворенность собой и своей жизненной ситуацией.",
            question3b: "Наблюдается умеренное настроение, довольно позитивное отношение и в целом оптимистичный взгляд в будущее.",
            question3c: "Наблюдаются непропорционально сильные эмоциональные реакции, " +
              "явная склонность к пессимистическому восприятию мира; проявление неудовлетворенности.",
            question4a: "В ходе обследования адекватно контролирует свое поведение и эмоции, " +
              "адекватно приспосабливается к возникающим обстоятельствам.",
            question4b: "В ходе обследования в целом контролирует свое поведение и эмоции; " +
              "чаще всего адекватно реагирует на возникающие обстоятельства.",
            question4c: "В ходе обследования наблюдаются трудности с контролем поведения и эмоций; " +
              "появляются неадекватные поведение или эмоции.",
          },
        },
        drivingVehicles: {
          headers: {
            drivingCharacteristics: "Характеристика маршрутов",
          },
          questionTexts: {
            question1: "Общий стаж вождения",
            question2: "Стаж работы водителем",
            question3: "Годовой пробег",
            question4: "Количество часов за рулем",
            question5: "Длина маршрутов",
            question6: "Разнообразие маршрутов",
            question7: "Время суток",
            question8: "Сменный водитель",
            question9: "Территория",
            question10: "Приостановка или лишение водительских прав",
            question11: "Заключение о неспособности к вождению",
            question12: "Наказание за нарушение ПДД или дорожное преступление",
            question13: "Участие в качестве водителя в автомобильной аварии",
            question14: "Участие в качестве водителя в дорожном происшествии",
            question15: "Дополнительные квалификации и лицензии",
          },
          questionAnswers: {
            question3a: "менее 3 000 км в год",
            question3b: "между 3 000 – 15 000 км в год",
            question3c: "между 15 000 - 70 000 км в год",
            question3d: "более 70 000 км в год",
            question3e: "затрудняюсь ответить",

            question4a: "менее 5 часов в неделю",
            question4b: "между 5 - 15 часов в неделю",
            question4c: "между 15 - 40 часов в неделю",
            question4d: "более 40 часов в неделю",
            question4e: "затрудняюсь ответить",

            question5a: "езжу преимущественно на коротких маршрутах",
            question5b: "езжу преимущественно на длинных маршрутах",
            question5c: "езжу как на длинных, так и на коротких маршрутах",

            question6a: "езжу обычно по постоянным маршрутам",
            question6b: "езжу чаще всего по разным маршрутам",

            question7a: "езжу чаще всего ночью",
            question7b: "езжу обычно днем",
            question7c: "езжу как днем, так и ночью",

            question8a: "езжу в основном самостоятельно без сменщика",
            question8b: "езжу чаще всего со сменщиком",

            question9a: "езжу в основном по городу",
            question9b: "езжу в основном за городом",

            question13a: "как виновник/соучастник",
            question13b: "как потерпевший",
            question13c: "не применимо",

            question14a: "как виновник/соучастник",
            question14b: "как потерпевший",
            question14c: "не применимо",

            question15a: "перевозка опасных материалов",
            question15b: "перевозка пассажиров",
            question15c: "инструктор по вождению",
            question15d: "экзаменатор по вождению",
            question15e: "отсутствуют",
          },
        },
        occupationalMedicine: {
          headers: {
            workInformation: "Информация о профессиональной деятельности",
            workCourseInformation: "История профессиональной карьеры",
            companyCarDrivers: "Водители служебных автомобилей",
            workingAtHeights: "Работа на высоте",
            forkliftOperators: "Операторы погрузчиков",
            minersAndMinerLifeguards: "Шахтеры и горноспасатели",
            trainDriver: "Машинисты",
          },
          questionTexts: {
            question1: "Полученная профессия",
            question2: "Текущая профессия",
            question3: "Место работы / учебы",
            question4: "Должность / выполняемые обязанности",
            question5: "Постоянные вредные/обременительные факторы на рабочем месте",
            question5f: "Какие?",
            question5g: "Какие?",
            question6: "Общий стаж работы",
            question7: "Стаж работы в текущей должности",
            question8: "Диагностировано профессиональное заболевание",
            question8a: "Когда? По какой причине?",
            question9: "Назначено пенсионное пособие",
            question9a: "По какой причине? С какого времени? До какого времени?",
            question10: "Обследуемый попал в производственную аварию",
            question10a: "Когда? Какими были последствия для здоровья?",
            question11: "Установлена инвалидность",
            question11a: "По какой причине? С какого времени? До какого времени?",
            question12: "Общий стаж вождения",
            question13: "Годовой пробег",
            question14: "Количество часов за рулем",
            question15: "Заключение о неспособности к вождению",
            question16: "Приостановка или лишение водительских прав",
            question17: "Наказание за нарушение ПДД или дорожное преступление",
            question18: "Участие в качестве водителя в автомобильной аварии",
            question19: "Участие в качестве водителя в дорожном происшествии",
            question20: "Наличие допуска к работе на высоте",
            question21: "Выполнение работ на высоте (если да, уточнить, сколько метров над землей)",
            question22: "Субъективное ощущение страха при нахождении на высоте",
            question23: "Несчастный случай при работе на высоте",
            question23a: "Был ли причинен ущерб имуществу или здоровью?",
            question24: "Наличие допуска к работе оператором погрузчика",
            question25: "Выполнение работ, связанных с обслуживанием и управлением погрузчиком",
            question26: "Несчастный случай при обслуживании или управлении погрузчиком",
            question26a: "Был ли причинен ущерб имуществу или здоровью?",
          },
          questionAnswers: {
            question5a: "Монотонная работа",
            question5b: "Постоянный большой поток информации",
            question5c: "Высокий уровень шума",
            question5d: "Работа с высокой психологической нагрузкой (ответственность и принятие решений)",
            question5e: "Ионизирующее излучение",
            question5f: "Химические факторы",
            question5g: "Другие",

            question13a: "менее 3 000 км в год",
            question13b: "между 3 000 – 15 000 км в год",
            question13c: "между 15 000 - 70 000 км в год",
            question13d: "более 70 000 км в год",
            question13e: "затрудняюсь ответить",

            question14a: "менее 5 часов в неделю",
            question14b: "между 5 - 15 часов в неделю",
            question14c: "между 15 - 40 часов в неделю",
            question14d: "более 40 часов в неделю",
            question14e: "затрудняюсь ответить",

            question18a: "как виновник/соучастник",
            question18b: "как потерпевший",
            question18c: "не применимо",

            question19a: "как виновник/соучастник",
            question19b: "как потерпевший",
            question19c: "не применимо",
          },
        },
        licensedActivity: {
          questionTexts: {
            question1: "Общий стаж работы в учреждении, занимающемся лицензированной деятельностью",
            question2: "Должность/выполняемые обязанности",
            question3: "Стаж работы в текущей должности в учреждении, занимающемся лицензированной деятельностью",
            question4a: "Участие в происшествии на рабочем месте",
            question4b: "Каковы были причины, последствия происшествия - был ли нанесен ущерб имуществу, здоровью?",
          },
        },
        detectivesSecurityGuardsAndWeaponPermits: {
          questionTexts: {
            question1: "Основные цели использования оружия:",
            question1g: "Какие?",
            question2: "Имело ли место когда-либо применение оружия или других средств прямого принуждения?",
            question3: "Как часто применялось оружие за последние 5 лет?",
          },
          questionAnswers: {
            question1a: "в профессиональных целях (детектив, охранник)",
            question1b: "в охотничьих целях",
            question1c: "в спортивных целях",
            question1d: "для личного пользования",
            question1e: "для музейных, памятных, научных целей",
            question1f: "другое",
          },
        },
      },
    },
  },
  Language: { pl: "PL", ru: "RU", uk: "UK" },
  SearchEngine: {
    header: "Поиск обследуемых",
    placeholderMobile: "Поиск",
    placeholderDesktop: "Имя, фамилия, ПЕСЕЛ/Идентификатор, дата назначения обследования (ГГГГ-ММ-ДД)",
    noResults: "Нет результатов поиска",
    noResultsForFilter: "Не найдено обследуемых, соответствующих введенным требованиям.",
    searchForExaminees: "Поиск обследуемых",
    enterFilter: "Вы можете искать по имени, фамилии, номеру ПЕСЕЛ/Идентификатору\n" +
      "или по дате назначения обследования \n" +
      "в формате ГГГГ-ММ-ДД.",
  },
  AdminSearchEngine: {
    header: "Поиск клиентов",
    placeholderMobile: "Поиск",
    placeholderDesktop: "название, ID, адрес, ИНН, дата создания, дата истечения срока действия учетной записи клиента в формате ГГГГ-ММ-ДД, имя, фамилия, электронная почта пользователя, дата истечения срока действия лицензии, методология, тест",
    noResults: "Нет результатов поиска",
    noResultsForFilter: "Не найдено клиентов, соответствующих введенным требованиям.",
    searchForExaminees: "Поиск клиентов",
    enterFilter: "Вы можете искать по названию, ID, адресу, ИНН, дате создания, дате истечения срока действия учетной записи клиента в формате ГГГГ-ММ-ДД, имени, фамилии, электронной почте пользователя, дате истечения срока действия лицензии в формате ГГГГ-ММ-ДД, методологии, тесту.",
  },
  Snackbars: {
    createExamineeSuccess: "Обследуемый успешно добавлен",
    editExamineeSuccess: "Обследуемый успешно отредактирован",
    createPsychLabSuccess: "Аккаунт клиента успешно добавлен",
    editPsychLabSuccess: "Аккаунт клиента успешно отредактирован",
    failedRequest: "Возникла проблема: ",
    movingExamineesSuccess: "Успешно перемещено в другую папку",
    passwordChangeSuccess: "Пароль успешно изменен",
    pinChangeSuccess: "ПИН-код успешно изменен",
    success: "Действие выполнено успешно",
    unrecognizedError: "Неопознанная ошибка, проверьте подключение к интернету",
    createExaminationSuccess: "Обследование успешно назначено",
    deleteExaminationSuccess: "Обследование успешно удалено",
    editPsychUserSuccess: "Пользователь успешно отредактирован",
    createPsychUserSuccess: "Пользователь успешно добавлен",
    createPsychLicSuccess: "Лицензия успешно добавлена",
    editPsychLicSuccess: "Лицензия успешно отредактирована",
  },
  UserSettings: {
    header: "Настройки аккаунта",
    headerChangePassword: "Изменение пароля",
    headerChangePin: "Изменение ПИН-кода",
    setNewPin: "Установить новый ПИН-код",
    pinInfo: "Введите ПИН-код, который вы будете использовать для входа в консоль психолога.",
    headerMobile: "Настройки",
    facilityInfo: "Информация об учреждении",
    userInfo: "Информация о пользователе",
    passwordChange: "Изменение пароля",
    passwordExpiry: "Срок действия вашего пароля истекает: ",
    pinChange: "Изменение ПИН-кода",
    infoSubHeaders: {
      labName: "Название",
      address: "Адрес",
      cin: "Регистрационный номер",
      tin: "ИНН",
      firstName: "Имя",
      lastName: "Фамилия",
      email: "Электронная почта",
      dateExpire: "Дата истечения аккаунта",
      licenseNumber: "Номер лицензии"
    },
    helpdeskMsgUser: "Для изменения информации об учреждении или пользователе позвоните по номеру:",
    helpdeskMsgLab: "Для изменения информации об учреждении или пользователе позвоните по номеру:",
  },
  Backend: {
    User_activation_code_has_already_been_used: "Код активации уже использован.",
    User_activation_code_does_not_exist: "Указанный код активации не существует.",
    User_with_given_id_does_not_exist: "Пользователь с указанным ID не существует.",
    Token_not_recognized: "Токен не распознан.",
    Unrecognized_token_Please_regenerate_password_reset_link: "Нераспознанный токен, пожалуйста, сгенерируйте новую ссылку для сброса пароля.",
    Invalid_token_Please_regenerate_password_reset_link: "Недействительный токен, пожалуйста, сгенерируйте новую ссылку для сброса пароля.",
    Validation_failed: "Проверка введенных данных не удалась.",
    Could_not_bind_users_PsychologicalLab_with_the_examinee: "Не удалось связать учреждение пользователя с обследуемым.",
    Could_not_bind_users_PsychologicalLab_with_the_directory: "Не удалось связать учреждение пользователя с папкой.",
    The_password_cannot_be_the_same_as_the_previous_one: "Пароль не может быть таким же, как предыдущий.",
    Incorrect_credentials: "Неверный пароль.",
    Unrecognized_email: "Адрес электронной почты не распознан или учетная запись не активирована.",
    Directory_has_related_Examinees: "Невозможно удалить Папку: Папка содержит связанных обследуемых.",
    Unable_to_delete_default_directory: "Невозможно удалить Папку по умолчанию.",
    The_new_pin_cannot_be_the_same_as_the_previous_one: "Новый ПИН-код не может быть таким же, как предыдущий.",
    Directory_with_given_name_already_exists: "Папка с указанным именем уже существует.",
    Examination_Card_does_not_have_a_defined_NormSet: "Карта обследования не имеет назначенной нормы обследования.",
    Examinee_with_the_given_id_exists_in_this_psychological_lab: "Обследуемый с указанным ID уже существует в базе данных.",
    The_Examinee_has_related_Examinations: "Невозможно удалить Обследуемого: У обследуемого есть связанные обследования.",
    Unrecognized_proxy_error_occurred_while_fetching_legacy_data: "Произошла нераспознанная ошибка при получении архивных данных.",
    Legacy_data_instance_not_found: "Архивные данные не найдены.",
    Legacy_data_validation_failed: "Ошибка проверки архивных данных.",
    Unable_to_parse_Examination_Aim: "Ошибка проверки Цели Обследования.",
    Unable_to_parse_Occupational_Medicine_Basic_Info_data: "Ошибка проверки данных Медицины Труда.",
    Ongoing_examination: "Невозможно удалить Обследование. Обследование в процессе.",
  },
  Onboarding: {
    errorText: "При установке пароля произошла ошибка, пожалуйста, попробуйте позже.",
    successTitle: "Пароль и ПИН-код установлены",
    success: "Ваши пароль и ПИН-код сохранены. Теперь вы можете впервые войти в приложение, используя свой адрес электронной почты и пароль.",
    setNewPasswordInfo: "Введите пароль, который вы будете использовать для входа в систему.",
    setPasswordFormLabel: "Установить пароль",
    setPinInfo: "Введите ПИН-код, который вы будете использовать для входа в консоль психолога.",
    setPinFormLabel: "Установите свой ПИН-код",
  },
  ResetPassword: {
    formHeader: "Сброс Пароля",
    resetInfo: "Введите адрес электронной почты вашей учетной записи, чтобы получить ссылку для сброса пароля.",
    resetEmailSentPt1: "На адрес электронной почты: ",
    resetEmailSentPt2: " отправлено сообщение с инструкциями по процессу сброса пароля.",
    errorText: "При сбросе пароля произошла ошибка, пожалуйста, попробуйте позже.",
    successTitle: "Пароль Сохранен",
    success: "Ваш пароль был изменен. Теперь вы можете войти в приложение, используя свой адрес электронной почты и новый пароль.",
    setNewPassword: "Установить новый пароль",
    setNewPasswordInfo: "Введите пароль, который вы будете использовать для входа в систему.",
  },
  FormLabels: {
    email: "Электронная почта",
    password: "Пароль",
    currentPassword: "Текущий пароль",
    newPassword: "Новый пароль",
    pin: "ПИН-код",
  },
  FormValidationErrors: {
    required: "Это поле обязательно для заполнения",
    email: "Введенное значение не является адресом электронной почты",
  },
  Login: {
    header: "ВХОД",
    adminHeader: "ПАНЕЛЬ АДМИНИСТРАТОРА",
    error: "Неверный адрес электронной почты или пароль.",
    warning: "Включен CAPS LOCK",
    reset: "Сбросить пароль",
    Instance_configured_incorrectly: "Ошибка конфигурации системы. Сообщите о проблеме в техническую поддержку.",
    No_recaptcha_token_provided: "Ошибка recaptcha. Сообщите о проблеме в техническую поддержку.",
    Validation_failed: "Неверный адрес электронной почты или пароль.",
    Captcha_error: "Ошибка при заполнении captcha. Пожалуйста, попробуйте снова.",
    Access_denied: "Доступ запрещен"
  },
  Directory: {
    defaultFolderName: "Папка по умолчанию",
    header: "Картотека обследуемых",
    popup: {
      create: "Введите название папки",
      edit: "Изменить название папки",
    },
    emptyState: {
      header: "В выбранной папке нет обследуемых",
      info: "Чтобы увидеть список обследуемых в папке, добавьте хотя бы одного обследуемого",
    },
    labels: {
      name: "Название папки",
      owner: "Владелец",
      examineeCount: "Люди",
    },
    kebabMenu: {
      addExaminee: "Добавить обследуемого",
      addDirectory: "Добавить папку",
      edit: "Изменить название папки",
      delete: "Удалить папку",
    },
    popups: {
      delete: {
        confirmationHeader: "Вы уверены, что хотите удалить папку?",
        confirmationText: "После удаления папку невозможно будет восстановить.",
      },
    },
  },
  DirectoryPreview: {
    headerPopup: "Переместить обследуемых",
    labels: {
      firstName: "Имя",
      lastName: "Фамилия",
      peselSlashIdentifier: "СНИЛС / Идентификатор",
      lastModification: "Последняя активность",
    },
    kebabMenu: {
      edit: "Редактировать данные обследуемого",
      move: "Переместить обследуемого",
      delete: "Удалить обследуемого",
    },
    breadcrumbs: "Картотека обследуемых",
  },
  Pagination: {
    outOf: "из",
  },
  PasswordExpired: {
    popup: {
      header: "Срок действия пароля истек",
      text: "Вы будете перенаправлены на процесс сброса пароля для установки нового.",
    },
    form: {
      header: "Установить новый пароль",
      text: "Введите пароль, который вы будете использовать для входа в систему.",
    },
    success: {
      header: "Пароль сохранен",
      text: "Ваш пароль был изменен. Теперь вы можете перейти к приложению.",
    },
  },
  DocumentationManagement: {
    snackbar: {
      save: "Документ успешно сохранен",
    },
    names: {
      ExaminationCard: "Карта обследования",
      ExaminationCardTEST2WORK: "Карта обследования TEST2WORK",
      ExaminationCardTEST2SAFE: "Карта обследования TEST2SAFE",
      ExaminationCardTEST2ARMS: "Карта обследования TEST2ARMS",
      ExaminationCardTEST2DRIVE: "Карта обследования TEST2DRIVE",
      PsychologicalConsultation: "Психологическая консультация",
      PsychologicalConsultationTEST2WORK: "Психологическая консультация",
      PsychologicalConsultationTEST2SAFE: "Психологическая консультация",
      PsychologicalConsultationTEST2ARMS: "Психологическая консультация",
      PsychologicalConsultationTEST2DRIVE: "Психологическая консультация",
      PsychologicalOpinion: "Психологическое заключение",
      PsychologicalOpinionTEST2WORK: "Психологическое заключение",
      PsychologicalOpinionTEST2SAFE: "Психологическое заключение - детективы",
      PsychologicalOpinionTEST2ARMS: "Психологическое заключение",
      PsychologicalOpinionTEST2DRIVE: "Психологическое заключение",
      DriverJudgment: "Заключение на право управления ТС",
      DriverJudgmentTEST2WORK: "Заключение на право управления ТС",
      DriverJudgmentTEST2SAFE: "Заключение на право управления ТС",
      DriverJudgmentTEST2ARMS: "Заключение на право управления ТС",
      DriverJudgmentTEST2DRIVE: "Заключение на право управления ТС",
      DrivingExaminerJudgment: "Заключение экзаменатора по вождению",
      DrivingExaminerJudgmentTEST2WORK: "Заключение экзаменатора по вождению",
      DrivingExaminerJudgmentTEST2SAFE: "Заключение экзаменатора по вождению",
      DrivingExaminerJudgmentTEST2ARMS: "Заключение экзаменатора по вождению",
      DrivingExaminerJudgmentTEST2DRIVE: "Заключение экзаменатора по вождению",
      DrivingInstructorJudgment: "Заключение инструктора по вождению",
      DrivingInstructorJudgmentTEST2WORK: "Заключение инструктора по вождению",
      DrivingInstructorJudgmentTEST2SAFE: "Заключение инструктора по вождению",
      DrivingInstructorJudgmentTEST2ARMS: "Заключение инструктора по вождению",
      DrivingInstructorJudgmentTEST2DRIVE: "Заключение инструктора по вождению",
      DrivingTechniqueInstructorJudgment: "Заключение инструктора по технике вождения",
      EmergencyVehicleDriverJudgment: "Заключение на управление спецтранспортом",
      EmergencyVehicleDriverJudgmentTEST2WORK: "Заключение на управление спецтранспортом",
      EmergencyVehicleDriverJudgmentTEST2SAFE: "Заключение на управление спецтранспортом",
      EmergencyVehicleDriverJudgmentTEST2ARMS: "Заключение на управление спецтранспортом",
      EmergencyVehicleDriverJudgmentTEST2DRIVE: "Заключение на управление спецтранспортом",
      FirearmLicenseJudgment: "Заключение на право владения оружием",
      FirearmLicenseJudgmentTEST2WORK: "Заключение на право владения оружием",
      FirearmLicenseJudgmentTEST2SAFE: "Заключение на право владения оружием",
      FirearmLicenseJudgmentTEST2ARMS: "Заключение на право владения оружием",
      FirearmLicenseJudgmentTEST2DRIVE: "Заключение на право владения оружием",
      MilitaryExplosivesJudgment: "Заключение на полицейское/военное оружие и материалы",
      MilitaryExplosivesJudgmentTEST2WORK: "Заключение на полицейское/военное оружие и материалы",
      MilitaryExplosivesJudgmentTEST2SAFE: "Заключение на полицейское/военное оружие и материалы",
      MilitaryExplosivesJudgmentTEST2ARMS: "Заключение на полицейское/военное оружие и материалы",
      MilitaryExplosivesJudgmentTEST2DRIVE: "Заключение на полицейское/военное оружие и материалы",
      OccupationalMedicineJudgmentTEST2WORK: "Заключение по медицине труда",
      OccupationalMedicineJudgmentTEST2SAFE: "Заключение по медицине труда",
      OccupationalMedicineJudgmentTEST2ARMS: "Заключение по медицине труда",
      OccupationalMedicineJudgmentTEST2DRIVE: "Заключение по медицине труда",
      PhysicalProtectionJudgment: "Заключение на лицензию охранника",
      PhysicalProtectionJudgmentTEST2WORK: "Заключение на лицензию охранника",
      PhysicalProtectionJudgmentTEST2SAFE: "Заключение - работник физической охраны",
      PhysicalProtectionJudgmentTEST2ARMS: "Заключение на лицензию охранника",
      PhysicalProtectionJudgmentTEST2DRIVE: "Заключение на лицензию охранника",
      ProfessionalDriverJudgment: "Заключение на должность водителя",
      ProfessionalDriverJudgmentTEST2WORK: "Заключение на должность водителя",
      ProfessionalDriverJudgmentTEST2SAFE: "Заключение на должность водителя",
      ProfessionalDriverJudgmentTEST2ARMS: "Заключение на должность водителя",
      ProfessionalDriverJudgmentTEST2DRIVE: "Заключение на должность водителя"
    },
    emptyState: {
      header: "Обследование не завершено",
    },
    report: {
      details: {
        assignDate: "Дата назначения обследования",
        endDate: "Дата завершения обследования",
        firstName: "Имя",
        PESEL: "СНИЛС",
        identificationNumber: "Идентификатор",
        idExamination: "ID обследования",
        lastName: "Фамилия",
        name: "Название обследования",
        status: "Статус обследования",
      },
      statusExamination: {
        skip: "Некоторые тесты были пропущены или прерваны",
      },
      header: {
        language: "Выберите язык отчета",
        standard: "Выберите стандарт",
        recipient: "Выберите получателя",
        interval: "Выберите доверительный интервал",
        reportInterpretation: "Интерпретационный отчет",
        reportDiagnostic: "Диагностический отчет",
      },
    },
    consultation: {
      breadcrumbs: "Психологическая консультация",
      examineeData: "Данные обследуемого",
      statement: "заключаю",
    },
    opinion: {
      breadcrumbs: "Психологическое заключение",
      breadcrumbsAppeal: "Апелляционное психологическое заключение",
      examineeData: "Данные обследуемого",
      statement: "НА ОСНОВАНИИ ПРОВЕДЕННОГО ПСИХОЛОГИЧЕСКОГО ОБСЛЕДОВАНИЯ ЗАКЛЮЧАЮ",
    },
    judgment: {
      isAppealTitle: "АПЕЛЛЯЦИОННОЕ ЗАКЛЮЧЕНИЕ",
      appeal: "На данное заключение может быть подана апелляция в",
      appealDate: "В течение",
      appealDay: "В течение",
      appealDay2: "дней с момента получения",
      appealDayTlt: "Количество дней",
      drivingLicense: "В отношении водительского удостоверения категории",
      judgmentNumber: "Психологическое заключение №",
      judgmentNumberAppeal: "Психологическое заключение №",
      legalBais: "Правовое основание обследования",
      statement: "Заключаю*",
      occupationalMedicineStatement: "Постановляю*",
      statementThat: "Заключаю, что",
      nextLegalBasis: "Правовое основание следующего обследования:",
      remarks: "Примечания",
      ART_1: "ст. 39к ч. 3 п. 1",
      ART_2: "ст. 39к ч. 3 п. 2",
      examiner_ART_1: "ст. 34 ч. 5 п. 1",
      examiner_ART_2: "ст. 34 ч. 5 п. 2",
      header: {
        base: "Заключение",
        baseAppeal: "Апелляционное заключение",
        baseAppealFooter: "Данное заключение является окончательным.",
        civilExplosives: "Лицензируемая деятельность - для гражданского использования",
        civilExplosivesAppeal: "Лицензируемая деятельность - для гражданского использования - апелляция",
        driver: "Управление транспортными средствами",
        emergencyVehicleDriver: "Водитель спецтранспорта",
        firearmLicense: "Разрешение на оружие",
        militaryExplosives: "Лицензируемая деятельность - военного или полицейского назначения",
        physicalProtection: "Работник охраны",
        profesionalDriver: "Должность водителя",
        drivingInstructor: "Инструктор",
        drivingExaminer: "Экзаменатор",
        occupationalMedicine: "Медицина труда"
      },
      emergencyVehicleDriver: {
        true: "отсутствуют противопоказания к управлению спецтранспортом или инкассаторским транспортом",
        false: "имеются противопоказания к управлению спецтранспортом или инкассаторским транспортом"
      },
      drivingVehicles: {
        true: "отсутствуют психологические противопоказания к управлению транспортными средствами",
        false: "имеются психологические противопоказания к управлению транспортными средствами"
      },
      physicalProtection: {
        true: "обладает способностью выполнять обязанности работника физической охраны",
        false: "не обладает способностью выполнять обязанности работника физической охраны"
      },
      civilExplosives: {
        true: "не проявляет нарушений психологического функционирования и, следовательно, может осуществлять деятельность по приобретению и хранению взрывчатых материалов гражданского назначения",
        false: "проявляет нарушения психологического функционирования и, следовательно, не может осуществлять деятельность по приобретению и хранению взрывчатых материалов гражданского назначения"
      },
      drivingInstructor: {
        true: "отсутствуют противопоказания к выполнению обязанностей инструктора/экзаменатора/инструктора по технике вождения",
        false: "имеются противопоказания к выполнению обязанностей инструктора/экзаменатора/инструктора по технике вождения"
      },
      drivingExaminer: {
        true: "отсутствуют противопоказания к выполнению обязанностей инструктора/экзаменатора/инструктора по технике вождения",
        false: "имеются противопоказания к выполнению обязанностей инструктора/экзаменатора/инструктора по технике вождения"
      },
      militaryExplosives: {
        true: "не проявляет существенных нарушений психологического функционирования, препятствующих осуществлению или руководству хозяйственной деятельностью в сфере производства и оборота взрывчатых материалов, оружия, боеприпасов и изделий и технологий военного или полицейского назначения",
        false: "проявляет существенные нарушения психологического функционирования, препятствующие осуществлению или руководству хозяйственной деятельностью в сфере производства и оборота взрывчатых материалов, оружия, боеприпасов и изделий и технологий военного или полицейского назначения"
      },
      professionalDriver: {
        true: "отсутствуют психологические противопоказания к работе в должности водителя",
        false: "имеются психологические противопоказания к работе в должности водителя"
      },
      firearmLicense: {
        true: "не относится к лицам, указанным в ст. 15 ч. 1 п. 3 закона от 21 мая 1999 г. об оружии и боеприпасах и может владеть оружием",
        false: "относится к лицам, указанным в ст. 15 ч. 1 п. 3 закона от 21 мая 1999 г. об оружии и боеприпасах и не может владеть оружием"
      },
      occupationalMedicine: {
        true: "отсутствуют психологические противопоказания к",
        false: "имеются психологические противопоказания к",
        justification: "Обоснование"
      },
    },
    ExaminationCard: {
      header: "Карта обследования",
      headerTEST2WORK: "Карта обследования TEST2WORK",
      headerTEST2SAFE: "Карта обследования TEST2SAFE",
      headerTEST2ARMS: "Карта обследования TEST2ARMS",
      headerTEST2DRIVE: "Карта обследования TEST2DRIVE",
      fetchUpdated: "Обновить данными опроса и результатами",
      tooltipFetchUpdated: "Это действие удалит все несохраненные изменения из Хода обследования",
      tooltipFetchUpdatedDisabled: "Для разблокировки действия выберите стандарт обследования.",
      tabs: {
        examinee: "Обследуемый",
        courseOfExamination: "Ход обследования",
        results: "Результаты психологического обследования"
      },
      examinee: {
        chosenNormSet: "Стандарт, применяемый при обследовании",
        examinationDate: "Дата обследования",
        previousExaminationDate: "Дата предыдущего обследования",
        aim: "Цель обследования",
        entityDirectingToExamination: "Направляющая организация",
        t2dAim: {
          drivingVehiclesAim: "Определение отсутствия или наличия противопоказаний к управлению транспортными средствами.",
          professionalDriverAim: "Определение отсутствия или наличия противопоказаний к работе в должности водителя.",
          emergencyDriverAim: "Определение отсутствия или наличия противопоказаний к управлению спецтранспортом или инкассаторским транспортом.",
          drivingInstructorAim: "Определение отсутствия или наличия противопоказаний к выполнению обязанностей инструктора/экзаменатора/инструктора по технике вождения."
        }
      },
      examinationCourse: {
        forcedUpdateHeader: "Обновить Карту Обследования данными из Опроса и Результатами",
        forcedUpdateBody: "Для разблокировки формы заполните Опрос для Обследования, выберите стандарт во вкладке Обследуемый и обновите Карту Обследования данными из Опроса и Результатами.",
        header: "Данные прямого опроса и наблюдения за обследуемым",
        methodology: "Методы обследования и результаты",
        recommendations: "Рекомендации",
        conclusions: "Выводы",
        conclusionsRecomendation: "Выводы и рекомендации",
        careerHistory: "История работы",
        examinationResultsHeader: "Название теста и результаты (в рамках рекомендованной методики)",
        examinationExtendedResultsHeader: "Название теста и дополнительные результаты",
        cognitiveResultsHeader: "Оценка и описание интеллектуальных способностей и познавательных процессов обследуемого (в рамках рекомендованной методики)",
        cognitiveExtendedResultsHeader: "Оценка и описание интеллектуальных способностей и познавательных процессов обследуемого - дополнительные результаты",
        personalityResultsHeader: "Оценка и описание личности обследуемого, включая функционирование в сложных ситуациях и социальную зрелость (в рамках рекомендованной методики)",
        personalityExtendedResultsHeader: "Оценка и описание личности обследуемого, включая функционирование в сложных ситуациях и социальную зрелость - дополнительные результаты",
        psychomotorResultsHeader: "Психомоторные показатели (в рамках рекомендованной методики)",
        psychomotorExtendedResultsHeader: "Психомоторные показатели - дополнительные результаты",
        occupationalMedicineAdditionalInfo: "Дополнительная информация - Медицина труда",
        examinationResultsT2WHeader: "Рекомендованная методика",
        examinationExtendedResultsT2WHeader: "Дополнительно",
        addExaminationExtendedResults: "Добавить дополнительный результат",
        deletePopup: {
          headerText: "Вы уверены, что хотите удалить этот результат?",
          contentText: "После удаления результата можно восстановить его исходное содержание путем обновления данных",
          confirmationText: "Да, я хочу удалить результат"
        }
      },
      judgment: {
        result: "Результат психологического обследования",
        sent: "Заключение/копия заключения отправлены, если применимо",
        judgment: "Содержание заключения",
        justification: "Обоснование заключения",
        justificationText: "Содержание обоснования",
        recommendations: "Рекомендации",
        to: "к",
        t2d: {
          lack: "отсутствуют",
          existing: "имеются",
          questions: {
            drivingVehiclesContraindication: "Противопоказания к управлению транспортными средствами.",
            professionalDriverContraindication: "Противопоказания к работе в должности водителя.",
            emergencyVehicleDriverContraindication: "Противопоказания к управлению спецтранспортом или инкассаторским транспортом.",
            drivingInstructorContraindication: "Противопоказания к выполнению обязанностей инструктора/экзаменатора/инструктора по технике вождения."
          }
        }
      },
    },
  },
};
