import * as styles from "../forms.module.scss";
import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import FormInputText from "../Inputs/FormInputText";
import { schemaPsychologicalLabUser } from "../schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import { handleNavigateToPreviousPage } from "../../../utils/paths";
import { selectPsychUserCUDSucceeded } from "../../../redux/selectors/adminPanel/psychologicalUserSelectors";
import { useIsFirstRender } from "usehooks-ts";
import { dispatchNotification } from "../../../utils/redux";
import { IsObjectEmpty } from "../../../utils";
import FormInputMultiRadio from "../Inputs/FormInputMultiRadio";
import { PsychUserData } from "../../../api/psychologicalLabApi";
import { editPsychUser } from "../../../redux/actions/adminPanel/psychologicalUserActions";
import FormInputDropdown from "../Inputs/FormInputDropdown";
import { langFormOptions, translateFormOption } from "../utils";

interface Props {
    psychUser: PsychUserData;
}

const FormEditPsychUser: FC<Props> = ({ psychUser }) => {
    const dispatch = useDispatch();
    const isFirst = useIsFirstRender();
    const editPsychUserCUDSuccess = useSelector(selectPsychUserCUDSucceeded);

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors, dirtyFields, isValid },
    } = useForm({
        shouldUnregister: false,
        reValidateMode: "onChange",
        resolver: yupResolver(schemaPsychologicalLabUser),
        defaultValues: {
            firstName: psychUser?.firstName,
            lastName: psychUser?.lastName,
            email: psychUser?.email,
            isActive: psychUser?.isActive,
            isStaff: psychUser?.isStaff,
            isSuperuser: psychUser?.isSuperuser,
            lang: psychUser?.lang,
        },
    });

    const onSubmit = (data: PsychUserData) => {
        const userId = psychUser && psychUser.id ? psychUser.id : 0;
        const laborId = psychUser && psychUser.labId ? psychUser.labId : 0;
        psychUser && dispatch(editPsychUser({ id: userId, labId: laborId, ...data }));
    };

    useEffect(() => {
        if (!isFirst && editPsychUserCUDSuccess) {
            void handleNavigateToPreviousPage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editPsychUserCUDSuccess]);

    useEffect(() => {
        if (!isFirst && !IsObjectEmpty(errors)) {
            (Object.keys(dirtyFields).length > 1) &&
                dispatchNotification("error", I18n.t("Forms.adminSnackbar.required"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors, isFirst]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.formWideContainer}>
                <div className={styles.fieldsContainer}>
                    <FormInputText
                        longInput="maxWidth"
                        control={control}
                        name="firstName"
                        label={I18n.t("Forms.adminFields.firstName")}
                        error={!!errors.firstName}
                        required
                    />
                    <FormInputText
                        longInput="maxWidth"
                        control={control}
                        name="lastName"
                        label={I18n.t("Forms.adminFields.lastName")}
                        error={!!errors.lastName}
                        required
                    />
                    <FormInputText
                        longInput="maxWidth"
                        control={control}
                        name="email"
                        label={I18n.t("Forms.adminFields.email")}
                        error={!!errors.email}
                        required
                    />
                    <FormInputDropdown
                        control={control}
                        name="lang"
                        label={I18n.t("Forms.fields.lang")}
                        options={translateFormOption(langFormOptions)}
                        error={"lang" in errors}
                        required
                        setValue={setValue}
                    />
                    <div className={styles.radioFieldContainer}>
                        <FormInputMultiRadio
                            label={I18n.t("Forms.adminFields.isStaff")}
                            required
                            name="isStaff"
                            row
                            setValue={setValue}
                            control={control}
                            options={[
                                { label: I18n.t("Forms.adminOptions.admin.user"), value: true },
                                { label: I18n.t("Forms.adminOptions.admin.baseuser"), value: false },
                            ]}
                            error={!!errors.isStaff}
                        />
                    </div>
                    <div className={styles.radioFieldContainer}>

                        <FormInputMultiRadio
                            label={I18n.t("Forms.adminFields.isActive")}
                            required
                            name="isActive"
                            row
                            setValue={setValue}
                            control={control}
                            options={[
                                { label: I18n.t("Forms.adminOptions.status.active"), value: true },
                                { label: I18n.t("Forms.adminOptions.status.disabled"), value: false },
                            ]}
                            error={!!errors.isActive}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.buttonsContainer}>
                <SecondaryButton
                    text={I18n.t("Buttons.cancel")}
                    event={handleNavigateToPreviousPage}
                />
                <PrimaryButton text={I18n.t("Buttons.save")} isSubmit />
            </div>
        </form>
    );
};

export default FormEditPsychUser;
