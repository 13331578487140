import * as styles from "../forms.module.scss";
import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import FormInputDropdown from "../Inputs/FormInputDropdown";
import FormInputText from "../Inputs/FormInputText";
import { schemaPsychologicalLab } from "../schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { createPsychLab } from "../../../redux/actions/adminPanel/psychologicalLabsActions";
import { themeFormOptions, translateFormOption } from "../utils";
import { I18n } from "react-redux-i18n";
import { handleNavigateToPreviousPage } from "../../../utils/paths";
import { CreatePsychLabData } from "../types";
import { selectPsychLabCUDSuccess } from "../../../redux/selectors/adminPanel/psychologicalLabsSelectors";
import { useIsFirstRender } from "usehooks-ts";
import { dispatchNotification } from "../../../utils/redux";
import { IsObjectEmpty } from "../../../utils";
import { useIsMobile } from "../../../utils/hooks";
import FormInputMultiRadio from "../Inputs/FormInputMultiRadio";
import FormDatePicker from "../Inputs/FormDatePicker";

const FormAddPsychLab: FC = () => {
  const dispatch = useDispatch();
  const isFirst = useIsFirstRender();
  const isMobile = useIsMobile();
  const createPsychLabCUDSuccess = useSelector(selectPsychLabCUDSuccess);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm({
    shouldUnregister: false,
    reValidateMode: "onChange",
    resolver: yupResolver(schemaPsychologicalLab),
    defaultValues: {
      name: "",
      address: "",
      cin: "",
      tin: "",
      city: "",
      postalCode: "",
      theme: "",
      isActive: true,
      dateExpire: "",
    },
  });

  const onSubmit = (data: CreatePsychLabData) => {
    dispatch(createPsychLab(data));
  };

  useEffect(() => {
    if (!isFirst && createPsychLabCUDSuccess) {
      void handleNavigateToPreviousPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPsychLabCUDSuccess]);

  useEffect(() => {
    if (!isFirst && !IsObjectEmpty(errors)) {
      dispatchNotification("error", I18n.t("Forms.adminSnackbar.required"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, isFirst]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formWideContainer}>
        <div className={styles.fieldsContainer}>
          <FormInputText
            longInput="maxWidth"
            control={control}
            name="name"
            label={I18n.t("Forms.adminFields.name")}
            error={!!errors.name}
            required
          />
          <FormInputDropdown
            control={control}
            name="theme"
            label={I18n.t("Forms.adminFields.theme")}
            options={translateFormOption(themeFormOptions)}
            setSelectedValue="optimisTheme"
            setValue={setValue}
            error={!!errors.theme}
            required
          />
          <FormInputMultiRadio
            label={I18n.t("Forms.adminFields.isActive")}
            required
            name="isActive"
            row
            setValue={setValue}
            control={control}
            options={[
              { label: I18n.t("Forms.adminOptions.status.active"), value: true },
              { label: I18n.t("Forms.adminOptions.status.disabled"), value: false },
            ]}
          />
          <FormDatePicker
            inputFormat={I18n.t("Format.ExamineeViewDateFormat")}
            control={control}
            name="dateExpire"
            label={I18n.t("Forms.adminFields.dateExpire")}
            setValue={setValue}
            required
            error={!!errors.dateExpire}
            disablePast
          />
        </div>
        <div
          className={
            isMobile
              ? styles.fieldsContainerMobile
              : styles.fieldsContainerDesktop
          }
        />
        <h2>{I18n.t("Forms.extraFields")}</h2>
        <div className={styles.fieldsContainer}>
          <FormInputText
            longInput="short"
            control={control}
            name="postalCode"
            label={I18n.t("Forms.adminFields.postalCode")}
          />
          <FormInputText
            control={control}
            name="city"
            label={I18n.t("Forms.adminFields.city")}
          />
          <FormInputText
            longInput="longer"
            control={control}
            name="address"
            label={I18n.t("Forms.adminFields.address")}
          />
          <FormInputText
            control={control}
            name="tin"
            label={I18n.t("Forms.adminFields.tin")}
          />
          <FormInputText
            control={control}
            longInput="maxWidth"
            name="cin"
            label={I18n.t("Forms.adminFields.cin")}
          />
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <SecondaryButton
          text={I18n.t("Buttons.cancel")}
          event={handleNavigateToPreviousPage}
        />
        <PrimaryButton text={I18n.t("Buttons.save")} isSubmit />
      </div>
    </form>
  );
};

export default FormAddPsychLab;
