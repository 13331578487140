/* eslint-disable camelcase */
export const uk = {
  HelpDocs: {
    accept: "Погоджуюсь",
    addExaminee: "Додати обстежуваного",
    addPsychLab: "Додати обліковий запис клієнта",
    addPsychUser: "Додати обліковий запис користувача",
    addUser: "Додати обліковий запис користувача",
    addDirectory: "Додати папку",
    assign: "Призначити",
    alreadyAssigned: "Призначено",
    assignExamination: "Призначити обстеження",
    alreadyAssignedExamination: "Обстеження в процесі",
    back: "Назад",
    backToLogin: "Повернутися на сторінку входу",
    begin: "Почати",
    cancel: "Скасувати",
    change: "Змінити",
    clear: "Очистити",
    close: "Закрити",
    confirm: "Підтвердити",
    continue: "Далі",
    createExamination: "Призначити обстеження",
    delete: "Видалити",
    deleteExaminee: "Видалити обстежуваного",
    deletePsychLab: "Видалити обліковий запис клієнта",
    deletePsychLabLic: "Видалити ліцензію",
    downloadAll: "Завантажити все",
    edit: "Редагувати",
    editExaminee: "Редагувати обстежуваного",
    editPsychLab: "Редагувати обліковий запис клієнта",
    editPsychLabLic: "Редагувати ліцензію",
    addPsychLabLic: "Додати ліцензію",
    editPsychLabUser: "Редагувати обліковий запис користувача",
    examinationWithNoLawBase: "Обстеження без правової підстави",
    examinationWithLawBase: "Обстеження з правовою підставою",
    exit: "Вихід",
    generate: "Згенерувати",
    generateAndPrint: "Згенерувати та роздрукувати",
    go: "Перейти",
    goToDashboard: "Перейти до панелі користувача",
    login: "Увійти",
    loginPsychLab: "Увійти до облікового запису клієнта",
    move: "Перемістити",
    moveExaminee: "Перемістити обстежуваного",
    moveExaminees: "Перемістити обстежуваних",
    ok: "Гаразд",
    print: "Друк",
    printContract: "Роздрукувати контракт",
    save: "Зберегти",
    savePassword: "Зберегти пароль",
    savePin: "Зберегти ПІН",
    saveAndPrint: "Зберегти та роздрукувати",
    saveSettings: "Зберегти налаштування",
    setPin: "Встановити ПІН",
    search: "Пошук",
    setPassword: "Встановити пароль",
    setNewPassword: "Встановити новий пароль",
    sendLink: "Надіслати посилання",
    skipTests: "Пропустити тести",
    understood: "Зрозуміло",
    showActivities: "Показати",
    exportActivities: "Експорт у файл XLS",
  },
  Format: {
    ViewDateTime: "DD.MM.YYYY HH:mm",
    FormDateFormat: "D MMMM YYYY",
    ExamineeViewDateFormat: "DD.MM.YYYY",
  },
  Buttons: {
    accept: "Погоджуюсь",
    addExaminee: "Додати обстежуваного",
    addPsychLab: "Додати обліковий запис клієнта",
    addPsychUser: "Додати обліковий запис користувача",
    addUser: "Додати обліковий запис користувача",
    addDirectory: "Додати папку",
    assign: "Призначити",
    alreadyAssigned: "Призначено",
    assignExamination: "Призначити обстеження",
    alreadyAssignedExamination: "Обстеження в процесі",
    back: "Назад",
    backToLogin: "Повернутися на сторінку входу",
    begin: "Розпочати",
    cancel: "Скасувати",
    change: "Змінити",
    clear: "Очистити",
    close: "Закрити",
    confirm: "Підтвердити",
    continue: "Далі",
    createExamination: "Призначити обстеження",
    delete: "Видалити",
    deleteExaminee: "Видалити обстежуваного",
    deletePsychLab: "Видалити обліковий запис клієнта",
    deletePsychLabLic: "Видалити ліцензію",
    downloadAll: "Завантажити все",
    edit: "Редагувати",
    editExaminee: "Редагувати обстежуваного",
    editPsychLab: "Редагувати обліковий запис клієнта",
    editPsychLabLic: "Редагувати ліцензію",
    addPsychLabLic: "Додати ліцензію",
    editPsychLabUser: "Редагувати обліковий запис користувача",
    examinationWithNoLawBase: "Обстеження без правової підстави",
    examinationWithLawBase: "Обстеження з правовою підставою",
    exit: "Вийти",
    generate: "Згенерувати",
    generateAndPrint: "Згенерувати та роздрукувати",
    go: "Перейти",
    goToDashboard: "Перейти до панелі користувача",
    login: "Увійти",
    loginPsychLab: "Увійти до облікового запису клієнта",
    move: "Перемістити",
    moveExaminee: "Перемістити обстежуваного",
    moveExaminees: "Перемістити обстежуваних",
    ok: "Гаразд",
    print: "Роздрукувати",
    printContract: "Роздрукувати контракт",
    save: "Зберегти",
    savePassword: "Зберегти пароль",
    savePin: "Зберегти ПІН",
    saveAndPrint: "Зберегти та роздрукувати",
    saveSettings: "Зберегти налаштування",
    setPin: "Встановити ПІН",
    search: "Пошук",
    setPassword: "Встановити пароль",
    setNewPassword: "Встановити новий пароль",
    sendLink: "Надіслати посилання",
    skipTests: "Пропустити тести",
    understood: "Зрозуміло",
    showActivities: "Показати",
    exportActivities: "Експорт до файлу XLS"
  },
  Common: {
    menu: {
      contact: "Допомога",
      dashboard: "Панель користувача",
      folders: "Записи обстежуваних",
      search: "Пошук",
      logout: "Вийти",
      settings: "Налаштування",
      psychlabs: "Записи клієнтів",
      breadcrumbsPsychLab: "Записи клієнтів"
    },
    seo: {
      addExaminee: "Додати обстежуваного",
      addPsychLabLic: "Додати ліцензію",
      addPsychLab: "Додати обліковий запис клієнта",
      dashboard: "Панель користувача",
      editExaminee: "Редагувати обстежуваного",
      helpdesk: "Допомога",
      helpdeskDocuments: "Документи допомоги",
      judgement: "Висновок",
      login: "Вхід",
      onboarding: "Онбординг",
      report: "Звіт",
      resetPassword: "Скидання пароля",
      setNewPassword: "Встановлення нового пароля",
      search: "Пошук",
      folders: "Картотека обстежуваних",
      settings: "Налаштування",
      examinationAssignment: "Призначення обстеження з правовою підставою",
      examinationCard: "Картка Обстеження"
    },
    warningPopup: {
      text: "Внесені вами зміни не будуть збережені",
      header: "Ви впевнені, що хочете вийти?",
      textSave: "Обов'язкові поля можуть також знаходитися на інших вкладках",
      headerSave: "Будь ласка, заповніть усі обов'язкові поля!",
      promptLogout: "Ви будете автоматично вийдете з системи через PROMPT_TIME_MINUTES хвилину через відсутність активності."
    },
    logAdminAsUserPopup: {
      text: "Не вдалося увійти до облікового запису клієнта",
      header: "Помилка"
    },
    deleteExamineePopup: {
      text: "Після видалення обстежуваного відновлення даних буде неможливим",
      header: "Ви впевнені, що хочете видалити обстежуваного?"
    },
  },
  Contact: {
    header: "Технічна підтримка",
  },
  Cookies: {
    header: "Дозвіл на використання cookies",
    consent: "Цей сайт використовує cookies для забезпечення найкращої роботи цього додатка. Якщо ви погоджуєтеся на зберігання інформації в cookies, натисніть кнопку ЗГОДЕН. Якщо ви не погоджуєтеся, ви не зможете використовувати додаток і виконувати призначені процедури."
  },
  Dashboard: {
    Dashboard: {
      header: "Нещодавно додані люди",
      footer: {
        lastLogin: "Останній вхід",
        lastFailedLogin: "Останній невдалий вхід",
        passwordExpired: "Термін дії вашого пароля закінчиться через",
        day: "день",
        days: "днів"
      },
      emptyState: {
        header: "Немає нещодавно доданих обстежуваних",
        info: "Щоб побачити список обстежуваних, додайте хоча б одного."
      },
      kebabMenu: {
        print: "Роздрукувати контракт",
        profile: "Перейти до профілю обстежуваного"
      }
    },
  },
  AdminDashboard: {
    header: "Картотека клієнтів",
    emptyState: {
      header: "Облікові записи клієнтів ще не додано",
      info: "Щоб побачити список клієнтів, додайте хоча б один обліковий запис."
    },
    emptyStateUsers: {
      header: "Облікові записи користувачів ще не додано",
      info: "Щоб побачити список користувачів, додайте хоча б один обліковий запис."
    },
    emptyStateLicense: {
      header: "Ліцензії ще не додано",
      info: "Щоб побачити ліцензію, додайте її."
    },
    kebabMenu: {
      print: "Роздрукувати контракт",
      profile: "Перейти до профілю обстежуваного"
    },
    PsychologicalLabsList: {
      labels: {
        name: "Назва клієнта",
        clientId: "ID клієнта",
        status: "Статус",
        psychCnt: "Кількість користувачів",
        createDate: "Дата створення",
        expireDate: "Термін дії облікового запису",
        details: "Деталі",
        usersCnt: "Кількість користувачів",
        examineesCnt: "Кількість обстежуваних",
        examinationsCnt: "Кількість обстежень",
        taskPerPage: "Рядків на сторінці:",
        all: "всі",
        of: "з",
        moreThen: "більше ніж"
      },
      labStatus: {
        enabled: "активний",
        disabled: "неактивний"
      }
    },
    Licence: {
      labels: {
        methodologyList: "Доступні методики",
        testsList: "Доступні тести",
        licenseDate: "Дата видачі ліцензії",
        expireDate: "Термін дії ліцензії"
      },
      deletePopup: {
        headerText: "Ви впевнені, що хочете видалити ліцензію?",
        contentText: "Після видалення ліцензії буде неможливо призначати обстеження",
        confirmationText: "Так, я хочу видалити ліцензію"
      }
    },
    PsychLab: {
      deletePopup: {
        headerText: "Ви впевнені, що хочете видалити обліковий запис клієнта?",
        contentText: "Після видалення облікового запису клієнта буде неможливо відновити дані",
        confirmationText: "Так, я хочу видалити обліковий запис клієнта"
      }
    },
    StatsList: {
      statFileName: "статистика_обстежень.xlsx"
    },
    ActivitiesList: {
      labels: {
        date: "Дата",
        action: "Дія",
        executor: "Виконавець",
        context: "Опис",
        status: "Статус",
        errorMsg: "Опис помилки"
      },
      labStatus: {
        success: "успішно",
        error: "помилка"
      }
    },
    UsersList: {
      deletePopup: {
        headerText: "Ви впевнені, що хочете видалити обліковий запис користувача?",
        contentText: "Після видалення облікового запису користувача його неможливо буде відновити",
        confirmationText: "Так, я хочу видалити обліковий запис користувача"
      },
      labels: {
        firstName: "Ім'я",
        lastName: "Прізвище",
        email: "Email",
        examineeCnt: "Кількість учасників",
        examinationCnt: "Кількість обстежень",
        isActive: "Статус",
        isStaff: "Роль"
      },
      labStatus: {
        enabled: "активний",
        disabled: "неактивний",
        adminRole: "адміністратор",
        superUserRole: "супер адміністратор",
        userRole: "користувач",
        userBaseRole: "реєстратор"
      },
      toolTip: {
        edit: "редагувати",
        delete: "видалити"
      }
    },
    PsychLabManagment: {
      breadcrumbs: {
        add: "Додати обліковий запис клієнта",
        edit: "Редагувати обліковий запис клієнта",
        addLicense: "Додати ліцензію",
        editLicense: "Редагувати ліцензію",
        addUser: "Додати обліковий запис користувача",
        editUser: "Редагувати обліковий запис користувача",
        dashboard: "Панель користувача",
        psychLabs: "Картотека клієнтів"
      },
      header: {
        add: "Додати обліковий запис клієнта",
        edit: "Редагувати обліковий запис клієнта",
        addUser: "Додати обліковий запис користувача",
        editUser: "Редагувати обліковий запис користувача",
        addLicense: "Додати ліцензію",
        editLicense: "Редагувати ліцензію"
      },
      PsychLabView: {
        labels: {
          details: "Деталі",
          download: "Завантажити",
          edit: "Редагувати",
          generate: "Згенерувати",
          name: "Назва тесту",
          nameFile: "Ім'я файлу",
          print: "Друк",
          result: "Результат",
          type: "Тип документа"
        },
        tabs: {
          psychLabData: "Дані клієнта",
          license: "Ліцензія",
          users: "Користувачі",
          activityLogs: "Журнал дій",
          activityLogsFile: "журнал_дій",
          activityId: "id",
          activityDate: "дата",
          activityAction: "назва дії",
          activityStatus: "статус",
          activityContext: "опис",
          activityExecutor: "виконавець",
          stats: "Статистика",
          theme: "Зовнішній вигляд"
        }
      }
    }
  },
  ExaminationAssignmentView: {
    header: "Призначити обстеження",
    labels: {
      chooseExaminationType: "Виберіть тип обстеження",
      chooseLawBase: "Виберіть правову підставу обстеження",
      description: "Опис",
      legalBase: "Правова підстава обстеження",
      reason: "Мета обстеження",
      suggestedTestMethods: "ПРОПОНОВАНІ МЕТОДИКИ",
      methodologyTest2Safe: "TEST2SAFE - методика психологічного тестування для працівників охорони та детективів",
      methodologyTest2Drive: "TEST2DRIVE - методика проведення обстежень водіїв в рамках транспортної психології",
      methodologyTest2Arms: "TEST2ARMS - методика психологічного тестування, ліцензована діяльність",
      methodologyTest2Work: "TEST2WORK - методика психологічного тестування в медицині праці"
    },
    popup: {
      header: "Деякі тести, рекомендовані для обраної методики, були вимкнені.",
      content: "Ви впевнені, що хочете пропустити ці тести?",
      checkbox: "Запам'ятати вибір для всіх обстежуваних"
    },
    license: "Тест недоступний у придбаній ліцензії"
  },
  PsychologicalTests: {
    psychomotor: "Психомоторні тести",
    cognitive: "Когнітивні тести",
    personality: "Особистісні тести"
  },
  ExamineeList: {
    labels: {
      contract: "Договір",
      examine: "Оцінка",
      firstName: "Ім'я",
      folder: "Папка",
      lastName: "Прізвище",
      examineLastDate: "Дата останнього обстеження",
      allFinishExam: "Призначено / завершено",
      peselSlashIdentifier: "PESEL / Ідентифікатор",
      profile: "Переглянути профіль",
      added: "Додано"
    },
  },
  ExaminationList: {
    withoutLegalBasis: "Обстеження без правової підстави",
    labels: {
      assign: "Призначено",
      assignDate: "Дата призначення",
      create: "Створено",
      createDate: "Дата створення",
      contract: "Контракт",
      documentation: "Документація",
      end: "Завершено",
      endDate: "Дата завершення",
      interview: "Інтерв'ю",
      identificator: "ID обстеження",
      language: "Мова",
      nameArchiveExamination: "Назва батареї",
      nameExamination: "Назва обстеження",
      start: "Розпочато",
      startDate: "Дата початку"
    },
    kebabMenu: {
      delete: "Видалити обстеження"
    },
    deletePopup: {
      headerText: "Ви впевнені, що хочете видалити обстеження?",
      contentText: "Після видалення обстеження його неможливо буде відновити",
      confirmationText: "Так, я хочу видалити обстеження"
    },
    toolTip: {
      contract: "Згенерувати контракт",
      interview: "Інтерв'ю",
      documentation: "Документація",
      delete: "Видалити"
    },
  },
  ArchiveExaminationList: {
    emptyState: {
      info: "Немає архівних обстежень, призначених цьому учаснику",
    },
  },
  ExaminationView: {
    header: "Документація",
    breadcrumbs: {
      archive: "Архівні обстеження"
    },
    details: {
      createDate: "Створення обстеження",
      endDate: "Завершення обстеження",
      identificator: "ID обстеження",
      language: "Мова",
      name: "Назва обстеження",
      startDate: "Дата початку обстеження",
      type: "Назва батареї"
    },
    protocols: {
      language: "Мова протоколів",
      testProtocol: "Протокол тесту"
    },
    labels: {
      details: "Деталі",
      download: "Завантажити",
      edit: "Редагувати",
      generate: "Згенерувати",
      name: "Назва тесту",
      nameFile: "Ім'я файлу",
      print: "Друк",
      result: "Результат",
      type: "Тип документа"
    },
    tabs: {
      documentation: "Документація",
      protocols: "Протоколи",
      reports: "Звіти"
    },
    common: {
      unavailableInLanguageOption: "Недоступно у вибраній мовній версії."
    },
    aimsKind: {
      aimKind_1: "Психологічний висновок - детективи",
      aimKind_3: "Висновок - працівник фізичної охорони",
      aimKind_8: "Апеляційний висновок - працівник фізичної охорони",
      aimKind_9: "Висновок - дозвіл на зброю",
      aimKind_10: "Висновок - ліцензована діяльність військового або поліцейського призначення",
      aimKind_11: "Висновок - ліцензована діяльність цивільного призначення",
      aimKind_12: "Апеляційний висновок - ліцензована діяльність цивільного призначення",
      aimKind_13: "Висновок - медицина праці",
      aimKind_14: "Висновок - медицина праці гірнича справа та гірничорятувальні роботи",
      aimKind_15: "Висновок - медицина праці ліцензія машиніста",
      aimKind_16: "Висновок - водій категорії C1, C1+E, C, C+E, D1, D1+E, D і D+E або керування трамваєм",
      aimKind_17: "Висновок - відновлення водійських прав категорії AM, A1, A2, A, B1, B, B+E і T",
      aimKind_18: "Висновок - відновлення водійських прав психологічні протипоказання",
      aimKind_19: "Висновок - продовження прав категорії C1, C1+E, C, C+E, D1, D1+E, D і D+E або керування трамваєм",
      aimKind_20: "Висновок - післяаварійне обстеження водія",
      aimKind_21: "Висновок - обстеження водія після вживання алкоголю або подібних речовин",
      aimKind_22: "Висновок - обстеження водія після перевищення 24 балів",
      aimKind_23: "Висновок - обстеження водія після щонайменше 2 порушень",
      aimKind_24: "Висновок - обстеження водія психологічні протипоказання",
      aimKind_25: "Висновок - водій спеціального транспорту",
      aimKind_26: "Висновок - післяаварійне обстеження водія (направлення старости)",
      aimKind_27: "Висновок - виконання обов'язків інструктора",
      aimKind_28: "Висновок - виконання обов'язків екзаменатора",
      aimKind_29: "Висновок - водій-учень або кандидат у середню школу категорії C або C+E",
      aimKind_30: "Висновок - водій-учень або кандидат на професійний курс категорії C або C+E",
      aimKind_31: "Висновок - професійний водій за направленням роботодавця",
      aimKind_32: "Висновок - самозайнятий професійний водій",
    },
  },
  ExamineeView: {
    anonymity: "Анонімний суб'єкт",
    userData: {
      defaultAnonFirstName: "Суб'єкт",
      defaultAnonLastName: "Анонімний",
      pesel: "Особистий номер",
      sex: "Стать",
      birthDate: "Дата народження",
      address: "Адреса",
      education: "Освіта",
      drivingLicense: "Категорія водійських прав",
      identityCard: "Документ, що посвідчує особу",
      serialNumber: "Серія та номер",
      identificator: "Ідентифікатор",
      drivingLicenseIssuer: "Орган, що видав водійські права",
      examineName: "Прізвище, ім'я, по батькові",
    },
    drivingLicense: {
      am: "AM",
      a1: "A1",
      a2: "A2",
      a: "A",
      b1: "B1",
      b: "B",
      be: "B+E",
      c: "C",
      c1: "C1",
      c1e: "C1+E",
      ce: "C+E",
      d: "D",
      d1: "D1",
      d1e: "D1+E",
      de: "D+E",
      t: "T",
      tramvay: "ТРАМВАЙ",
    },
    tabs: {
      archive: "Архівні обстеження",
      data: "Дані суб'єкта",
      examination: "Обстеження",
    },
    kebabMenu: {
      edit: "Редагувати дані суб'єкта",
      delete: "Видалити суб'єкта",
    },
    emptyState: {
      header: "Немає обстежень, призначених цьому суб'єкту",
      info: "Щоб переглянути список обстежень, призначте хоча б одне обстеження.",
    },
    translations: {
      passport: "паспорт",
      id_card: "посвідчення особи",
      male: "чоловік",
      female: "жінка",
      primary: "початкова освіта",
      lowerSecondary: "базова середня освіта",
      basicVocational: "професійно-технічна освіта",
      basicIndustry: "базова професійна освіта",
      secondaryIndustry: "середня професійна освіта",
      secondary: "повна загальна середня освіта",
      higher: "вища освіта",
    },
  },
  ExamineeManagment: {
    breadcrumbs: {
      add: "Додати суб'єкта",
      edit: "Редагувати суб'єкта",
      dashboard: "Панель користувача",
      directories: "Картотека суб'єктів",
    },
    header: {
      add: "Додати нового суб'єкта",
      edit: "Редагувати дані суб'єкта",
    },
  },
  Forms: {
    adminFields: {
      methodologyTlt: "Методологія",
      testPsychMotorTlt: "психомоторні тести",
      testCogTlt: "когнітивні тести",
      testPersonTlt: "особистісні тести",
      address: "Вулиця та номер будинку",
      name: "Назва",
      firstName: "Ім'я",
      lastName: "Прізвище",
      email: "Електронна пошта",
      password: "Пароль",
      pin: "ПІН-код",
      isActive: "Статус облікового запису",
      isStaff: "Роль",
      dateExpire: "Термін дії облікового запису",
      dateLicExpire: "Термін дії ліцензії",
      licMethodologies: "Доступні методології",
      licTests: "Доступні тести",
      city: "Місто",
      postalCode: "поштовий індекс",
      clientId: "ID клієнта",
      cin: "Реєстраційний номер",
      tin: "ІПН",
      theme: "Оформлення",
      examineesCnt: "Кількість суб'єктів",
      usersCnt: "Кількість користувачів",
      dateFrom: "період з",
      dateTo: "період по",
      activityName: "дія",
      activityDetails: "Деталі",
      status: "статус",
    },
    adminOptions: {
      theme: {
        optimisTheme: "optimis",
        defaultTheme: "стандартна",
      },
      status: {
        active: "активний",
        disabled: "неактивний",
      },
      admin: {
        admin: "адміністратор",
        user: "користувач",
        baseuser: "реєстратор",
      },
    },
    adminSnackbar: {
      required: "Необхідно заповнити всі обов'язкові поля.",
      email_unique_error: "Дана адреса електронної пошти вже існує в базі даних!",
      email_wrong_error: "Вказана адреса електронної пошти недійсна!",
    },
    fields: {
      address: "Вулиця та номер будинку",
      additionalInfo: "Додаткова інформація",
      appealAuthority: "Назва установи",
      appealDeadline: "Дата",
      birthDate: "Дата народження",
      city: "Місто",
      country: "Країна",
      diffrentIdentificationNumber: "Інший ідентифікатор",
      directory: "Папка",
      documentDate: "Дата видачі",
      drivingLicense: "Категорія водійських прав",
      drivingLicenseIssuer: "Орган видачі водійських прав",
      education: "Освіта",
      identificationNumber: "Особистий номер",
      identityDocumentType: "Документ, що посвідчує особу",
      identityDocumentNumber: "Серія та номер",
      interval: "Виберіть довірчий інтервал",
      judgmentNumber: "Номер рішення",
      languageReport: "Виберіть мову звіту",
      legalBasis: "Правова підстава обстеження",
      months: "місяці",
      name: "Ім'я",
      nextExamination: "Дата наступного обстеження",
      postalCode: "Поштовий індекс",
      recipient: "Виберіть отримувача",
      sex: "Стать",
      standard: "Виберіть стандарт",
      surname: "Прізвище",
      years: "Років",
      year: "Рік",
      examineeRegistryId: "Номер у реєстрі обстежуваних",
      age: "Вік",
      date: "Дата",
      institutionName: "Назва установи",
      lang: "Мова",
    },
    options: {
      activitiesStatus: {
        ok: "ок",
        user_error: "помилка користувача",
        server_error: "помилка сервера",
      },
      lang: {
        polish: "Польська",
        english: "Англійська",
        espaniol: "Іспанська",
        mexico: "Мексиканська іспанська",
        russian: "Російська",
        ukrain: "Українська",
      },
      activities: {
        ALL: "всі",
        AUDIT_ERROR: "Помилка журналу активності",
        ALL_ITEMS: "Всі дії",
        APP_REGISTRATION: "Реєстрація додатку",
        TEST_RESULT_SUBMITTED: "Результати тесту надіслано",
        CONSOLE_PIN_VERIFICATION: "Перевірка ПІН-коду на консолі користувача",
        SAVE_AGREEMENTS: "Збереження згод на обстеження та обробку даних",
        TEST_SKIPPED: "Тест пропущено на консолі",
        EXAMINATION_STARTED: "Обстеження розпочато",
        EXAMINATION_TERMINATED: "Обстеження завершено",
        ADMIN_LOGGED_OUT: "Адміністратор вийшов із системи",
        ADMIN_CHANGE_LAB_ID: "Адміністратор увійшов в обліковий запис клієнта",
        USER_LOGGED_IN: "Користувач увійшов у систему",
        USER_LOGGED_OUT: "Користувач вийшов із системи",
        PASSWORD_CHANGE_REQUEST: "Запит на зміну пароля",
        PASSWORD_CHANGE: "Зміна пароля",
        PASSWORD_CHANGE_EXPIRED: "Зміна пароля після закінчення терміну дії",
        PIN_CHANGE: "Зміна ПІН-коду",
        DIRECTORY_CREATED: "Папку створено",
        DIRECTORY_NAME_CHANGED: "Назву папки змінено",
        DIRECTORY_DELETED: "Папку видалено",
        EXAMINEE_ADDED: "Суб'єкта додано",
        EXAMINEE_DATA_CHANGE: "Дані суб'єкта змінено",
        EXAMINEE_DELETED: "Дані суб'єкта видалено",
        EXAMINEE_MOVED_TO_DIFFERENT_DIRECTORY: "Дані суб'єкта переміщено до іншої папки",
        EXAMINATION_ASSIGNED: "Обстеження призначено",
        EXAMINATION_DELETED: "Обстеження видалено",
        EXAMINATION_REPORT_DOWNLOADED: "Документи звіту завантажено",
        CONTRACT_DOWNLOADED: "Контракт завантажено",
        EXAMINATION_PROTOCOL_DOWNLOADED: "Протокол обстеження завантажено",
        EXAMINATION_PROTOCOLS_ZIP_DOWNLOADED: "Протоколи обстеження ZIP завантажено",
        INTERVIEW_CHANGED: "Інтерв'ю змінено",
        INTERVIEW_DOWNLOADED: "Інтерв'ю завантажено",
        ADMIN_LICENCE_ADDED: "Додано ліцензію для облікового запису клієнта",
        ADMIN_LICENCE_CHANGED: "Змінено ліцензію облікового запису клієнта",
        ADMIN_LICENCE_DELETED: "Видалено ліцензію облікового запису клієнта",
        ADMIN_PSYCHOLOGICAL_LAB_ADDED: "Додано обліковий запис клієнта",
        ADMIN_PSYCHOLOGICAL_LAB_DELETED: "Видалено обліковий запис клієнта",
        ADMIN_PSYCHOLOGICAL_LAB_CHANGED: "Змінено дані облікового запису клієнта",
        ADMIN_USER_ADDED: "Додано користувача до облікового запису клієнта",
        ADMIN_USER_DELETED: "Видалено користувача з облікового запису клієнта",
        ADMIN_USER_CHANGED: "Змінено дані користувача облікового запису клієнта",
        ADMIN_LOGGED_IN: "Адміністратор увійшов у систему",
      },
      norms: {
        norm_1: "OPT_1.0.PL",
        norm_2: "OPT_1.0.UA",
        norm_3: "OPT_1.0.RU",
      },
      bool: {
        true: "Так",
        false: "Ні",
      },
      education: {
        primary: "Початкова",
        lowerSecondary: "Базова середня",
        secondary: "Повна загальна середня",
        basicVocational: "Професійно-технічна",
        basicIndustry: "Базова професійна",
        secondaryIndustry: "Середня професійна",
        higher: "Вища",
      },
      identityDocument: {
        idCard: "Посвідчення особи",
        passport: "Паспорт",
      },
      sex: {
        female: "Жінка",
        male: "Чоловік",
      },
      country: {
        poland: "Polska",
        austria: "Austria",
        bulgaria: "Bułgaria",
        croatia: "Chorwacja",
        cyprus: "Cypr",
        czechRepublic: "Republika Czeska",
        denmark: "Dania",
        estonia: "Estonia",
        finland: "Finlandia",
        france: "Francja",
        greece: "Grecja",
        spain: "Hiszpania",
        netherlands: "Holandia",
        ireland: "Irlandia",
        lithuania: "Litwa",
        luxembourg: "Luksemburg",
        latvia: "Łotwa",
        malta: "Malta",
        germany: "Niemcy",
        portugal: "Portugalia",
        romania: "Rumunia",
        slovakia: "Słowacja",
        slovenia: "Słowenia",
        sweden: "Szwecja",
        hungary: "Węgry",
        italy: "Włochy",
        afghanistan: "Afganistan",
        albania: "Albania",
        angola: "Angola",
        saudiArabia: "Arabia Saudyjska",
        argentina: "Argentyna",
        armenia: "Armenia",
        azerbaijan: "Azerbejdżan",
        bangladesh: "Bangladesz",
        belarus: "Białoruś",
        bolivia: "Boliwia",
        bosniaAndHerzegovina: "Bośnia i Hercegowina",
        brazil: "Brazylia",
        chile: "Chile",
        china: "Chiny",
        chad: "Chad",
        montenegro: "Czarnogóra",
        democraticRepublicOfTheCongo: "Demokratyczna Republika Konga",
        dominicanRepublic: "Republika Dominikany",
        ecuador: "Ekwador",
        ethiopia: "Etiopia",
        gambia: "Gambia",
        ghana: "Ghana",
        georgia: "Gruzja",
        guatemala: "Gwatemala",
        honduras: "Honduras",
        india: "Indie",
        indonesia: "Indonezja",
        iran: "Iran",
        iceland: "Islandia",
        israel: "Izrael",
        jamaica: "Jamajka",
        japan: "Japonia",
        yemen: "Jemen",
        jordan: "Jordania",
        cambodia: "Kambodża",
        cameroon: "Kamerun",
        qatar: "Katar",
        kazakhstan: "Kazachstan",
        congo: "Kongo",
        southKorea: "Korea Południowa",
        laos: "Laos",
        northernMacedonia: "Północna Macedonia",
        madagascar: "Madagaskar",
        malaysia: "Malezja",
        mali: "Mali",
        mauritania: "Mauritania",
        mexico: "Meksyk",
        myanmar: "Myanmar",
        moldova: "Moldova",
        mozambique: "Mozambik",
        namibia: "Namibia",
        niger: "Niger",
        nigeria: "Nigeria",
        nicaragua: "Nikaragua",
        norway: "Norwegia",
        oman: "Oman",
        panama: "Panama",
        paraguay: "Paragwaj",
        peru: "Peru",
        centralAfricanRepublic: "Republika Środkowoafrykańska",
        russia: "Rosja",
        rwanda: "Rwanda",
        elSalvador: "Salwador",
        senegal: "Senegal",
        serbia: "Serbia",
        somalia: "Somali",
        unitedStatesOfAmerica: "Stany Zjednoczone Ameryki",
        sudan: "Sudan",
        southSudan: "Południowy Sudan",
        switzerland: "Szwajcaria",
        tajikistan: "Tadżykistan",
        thailand: "Tajlandia",
        turkey: "Turcja",
        uganda: "Uganda",
        ukraine: "Ukraina",
        uruguay: "Urugwaj",
        venezuela: "Wenezuela",
        unitedKingdom: "Zjednoczone Królestwo",
        vietnam: "Wietnam",
        zambia: "Zambia",
        unitedArabEmirates: "Zjednoczone Emiraty Arabskie",
        zimbabwe: "Zimbabwe",
      },
      recipient: {
        examinee: "Обстежуваний",
        psychologist: "Психолог",
        specialist: "Спеціаліст",
      },
      languagesDocumentation: {
        pl: "польська",
        en: "англійська",
      }
    },
    extraFields: "Додаткові дані",
    requiredFields: "Обов'язкові дані",
    anonymous: "Анонімне обстеження",
    snackbar: {
      peselLength: "Особистий номер повинен складатися з 11 цифр",
      peselNumber: "Особистий номер повинен містити лише цифри",
      required: "Необхідно заповнити всі обов'язкові поля.",
    }
  },
  FormValidation: {
    max: "Назва не може перевищувати 128 символів",
    required: "Це поле є обов'язковим",
    Pin: {
      length: "Повинен містити 4 цифри",
    },
    Password: {
      length: "Мінімум 8 символів",
      characters: "Повинен містити велику літеру, малу літеру, спеціальний символ та цифру",
    },
  },
  Helpdesk: {
    header: "Допомога",
    subheader: "Служба підтримки",
    phoneNo: "тел.",
    mobile: "моб.",
    help: "Віддалена підтримка",
    contact: "Контакти",
    documents: {
      header: "Довідкові документи",
      labels: {
        download: "Завантажити",
        name: "Назва документа",
      },
    },
  },
  Interview: {
    header: "Інтерв'ю",
    inputAdditionalInformation: "Введіть додаткову інформацію",
    inputHoursOfWork: "Введіть кількість робочих годин за останню добу",
    years: "Років",
    months: "Місяців",
    hours: "Годин",
    tabs: {
      currentPsychophysicalState: "Стан здоров'я",
      socialAndFamilySituation: "Соціальна та сімейна ситуація",
      observation: "Спостереження",
      drivingVehicles: "Керування транспортними засобами",
      occupationalMedicine: "Професійна медицина",
      securityAndDetectives: "Охоронні та детективні послуги",
      licensedActivities: "Ліцензована діяльність",
    },
    questions: {
      questionAnswers: {
        yes: "так",
        no: "ні",
      },
      questionTexts: {
        when: "Коли?",
        sinceWhen: "З якого часу?",
        forHowLong: "До якого часу?",
        why: "З якої причини?",
        whatAreTheConsequences: "Які були наслідки для здоров'я?",
        describeTheCircumStancesOfUsage: "Опишіть обставини використання",
      },
      methodologies: {
        currentPsychophysicalState: {
          headers: {
            currentState: "Поточний психофізичний стан",
            criticalEvents: "Критичні події",
          },
          questionExtendedTexts: {
            commonText: "Опишіть обставини цієї події/ситуації",
          },
          questionTexts: {
            question1: "Чи вживав обстежуваний алкоголь протягом останніх 48 годин?",
            question2: "Чи працював обстежуваний професійно протягом останньої або передостанньої ночі?",
            question3: "Кількість робочих годин за останню добу",
            question4: "Травма голови, що потребувала медичної допомоги",
            question5: "Втрата свідомості",
            question6: "Перебування в лікарні",
            question7: "Чи було колись або є зараз лікування/чи був колись діагноз наступних захворювань:",
            question7a: "a. захворювання травної системи (виразка шлунка або дванадцятипалої кишки, порушення функції кишечника тощо)",
            question7b: "b. захворювання серця та системи кровообігу (ішемічна хвороба, підвищений артеріальний тиск)",
            question7c: "c. порушення слуху або зору (за винятком носіння коригуючих окулярів)",
            question7d: "d. діабет",
            question7e: "e. епілепсія",
            question7f: "f. психічні захворювання (депресія, невроз, психіатричне лікування (уточнити про психіатричне лікування близького члена сім'ї)",
            question8: "Регулярний прийом ліків (уточнити, чи знає про побічні ефекти та запитати про інші ліки, що впливають на психічні функції)",
            question9: "Проблеми зі сном",
            question10: "Звернення за допомогою до:",
            question10a: "a. психолога",
            question10b: "b. психіатра",
            question10c: "c. невролога",
            question11: "Довідка про інвалідність",
            question12: "Проблеми, пов'язані з вживанням алкоголю",
            question13: "Перебування у витверезнику",
            question14: "Вживання одурманюючих/психоактивних речовин/наркотиків",
            question15: "Пропозиції лікаря або близької людини звернутися за лікуванням через нервозність, розсіяність або проблеми з пам'яттю чи орієнтацією",
          },
        },
        socialAndFamilySituation: {
          questionTexts: {
            question1: "Серйозний конфлікт або проблема, пов'язана з роботою " +
              "(наприклад, нещасний випадок на роботі, догана, трудовий суд, дисциплінарне звільнення, мобінг)",
            question2: "Проблеми в період навчання " +
              "(наприклад, повторення класу, виключення зі школи, призупинення прав учня)",
            question3: "Серйозний сімейний конфлікт або проблеми в особистих ситуаціях " +
              "(наприклад, судові справи, участь у бійках, можна уточнити про сімейний стан, дітей)",
            question4: "Ситуація прямої загрози життю (наприклад, пожежа, повінь та інші катастрофи)",
            question5: "Досвід фізичного насильства щодо себе (наприклад, напад, крадіжка, побиття)",
            question6: "Участь у громадських організаціях (наприклад, волонтерство)",
            question7: "Судимість (наприклад, отримання штрафів, вирок суду)",
          },
        },
        observation: {
          questionTexts: {
            question1: "Контакт із співрозмовником",
            question2: "Мова та риторика",
            question3: "Настрій та ставлення",
            question4: "Самовладання та контроль поведінки й емоцій",
          },
          questionAnswers: {
            question1a: "Встановлює дуже добрий контакт із співрозмовником; підтримує зоровий контакт; " +
              "поводиться впевнено та природно, не дозволяє вивести себе з рівноваги чи виявити нетерпіння, " +
              "поводиться спонтанно та природно.",
            question1b: "Встановлює контакт із співрозмовником; підтримує зоровий контакт; поводиться природно, " +
              "хоча помітні ознаки певного хвилювання.",
            question1c: "Поводиться відсторонено, уникає зорового контакту; поводиться неприродно, " +
              "малі подразники викликають втрату рівноваги, видно явні ознаки нервозності та нетерпіння.",
            question2a: "Формулює висловлювання, використовуючи правильну мову, " +
              "будує чіткі, зрозумілі речення, підтримує логічну структуру мовлення, " +
              "використовує багатий словниковий запас.",
            question2b: "Формулює висловлювання правильно, " +
              "загалом підтримує їх логічну структуру; словниковий запас середній, але правильний.",
            question2c: "Формулює короткі, формальні висловлювання, часто незв'язні або незрозумілі; " +
              "використовує неправильні фрази та формулювання; речення дуже прості, " +
              "словниковий запас дуже бідний, трапляються граматичні помилки.",
            question3a: "Спостерігається дуже веселий, оптимістичний настрій; задоволеність собою та своєю життєвою ситуацією.",
            question3b: "Спостерігається помірний настрій, досить позитивне ставлення та загалом оптимістичний погляд у майбутнє.",
            question3c: "Спостерігаються непропорційно сильні емоційні реакції, " +
              "явна схильність до песимістичного сприйняття світу; прояв незадоволення.",
            question4a: "Під час обстеження адекватно контролює свою поведінку та емоції, " +
              "адекватно пристосовується до виникаючих обставин.",
            question4b: "Під час обстеження загалом контролює свою поведінку та емоції; " +
              "найчастіше адекватно реагує на виникаючі обставини.",
            question4c: "Під час обстеження спостерігаються труднощі з контролем поведінки та емоцій; " +
              "з'являються неадекватна поведінка або емоції.",
          },
        },
        drivingVehicles: {
          headers: {
            drivingCharacteristics: "Характеристика маршрутів",
          },
          questionTexts: {
            question1: "Загальний стаж водіння",
            question2: "Стаж роботи водієм",
            question3: "Річний пробіг",
            question4: "Кількість годин за кермом",
            question5: "Довжина маршрутів",
            question6: "Різноманітність маршрутів",
            question7: "Час доби",
            question8: "Змінний водій",
            question9: "Територія",
            question10: "Призупинення або позбавлення водійських прав",
            question11: "Висновок про нездатність до водіння",
            question12: "Покарання за порушення ПДР або дорожній злочин",
            question13: "Участь як водія в автомобільній аварії",
            question14: "Участь як водія в дорожній пригоді",
            question15: "Додаткові кваліфікації та ліцензії",
          },
          questionAnswers: {
            question3a: "менше 3 000 км на рік",
            question3b: "між 3 000 – 15 000 км на рік",
            question3c: "між 15 000 - 70 000 км на рік",
            question3d: "більше 70 000 км на рік",
            question3e: "важко сказати",

            question4a: "менше 5 годин на тиждень",
            question4b: "між 5 - 15 годин на тиждень",
            question4c: "між 15 - 40 годин на тиждень",
            question4d: "більше 40 годин на тиждень",
            question4e: "важко сказати",

            question5a: "їжджу переважно на коротких маршрутах",
            question5b: "їжджу переважно на довгих маршрутах",
            question5c: "їжджу як на довгих, так і на коротких маршрутах",

            question6a: "їжджу зазвичай постійними маршрутами",
            question6b: "їжджу найчастіше різними маршрутами",

            question7a: "їжджу найчастіше вночі",
            question7b: "їжджу зазвичай вдень",
            question7c: "їжджу як вдень, так і вночі",

            question8a: "їжджу в основному самостійно без змінника",
            question8b: "їжджу найчастіше зі змінником",

            question9a: "їжджу в основному по місту",
            question9b: "їжджу в основному за містом",

            question13a: "як винуватець/співвинуватець",
            question13b: "як потерпілий",
            question13c: "не застосовується",

            question14a: "як винуватець/співвинуватець",
            question14b: "як потерпілий",
            question14c: "не застосовується",

            question15a: "перевезення небезпечних матеріалів",
            question15b: "перевезення пасажирів",
            question15c: "інструктор з водіння",
            question15d: "екзаменатор з водіння",
            question15e: "відсутні",
          },
        },
        occupationalMedicine: {
          headers: {
            workInformation: "Інформація про професійну діяльність",
            workCourseInformation: "Історія професійної кар'єри",
            companyCarDrivers: "Водії службових автомобілів",
            workingAtHeights: "Робота на висоті",
            forkliftOperators: "Оператори навантажувачів",
            minersAndMinerLifeguards: "Шахтарі та гірничорятувальники",
            trainDriver: "Машиністи",
          },
          questionTexts: {
            question1: "Здобута професія",
            question2: "Поточна професія",
            question3: "Місце роботи / навчання",
            question4: "Посада / виконувані обов'язки",
            question5: "Постійні шкідливі/обтяжливі фактори на робочому місці",
            question5f: "Які?",
            question5g: "Які?",
            question6: "Загальний стаж роботи",
            question7: "Стаж роботи на поточній посаді",
            question8: "Діагностовано професійне захворювання",
            question8a: "Коли? З якої причини?",
            question9: "Призначено пенсійну допомогу",
            question9a: "З якої причини? З якого часу? До якого часу?",
            question10: "Обстежуваний потрапив у виробничу аварію",
            question10a: "Коли? Якими були наслідки для здоров'я?",
            question11: "Встановлено інвалідність",
            question11a: "З якої причини? З якого часу? До якого часу?",
            question12: "Загальний стаж водіння",
            question13: "Річний пробіг",
            question14: "Кількість годин за кермом",
            question15: "Висновок про нездатність до водіння",
            question16: "Призупинення або позбавлення водійських прав",
            question17: "Покарання за порушення ПДР або дорожній злочин",
            question18: "Участь як водія в автомобільній аварії",
            question19: "Участь як водія в дорожній пригоді",
            question20: "Наявність допуску до роботи на висоті",
            question21: "Виконання робіт на висоті (якщо так, уточнити, скільки метрів над землею)",
            question22: "Суб'єктивне відчуття страху під час перебування на висоті",
            question23: "Нещасний випадок під час роботи на висоті",
            question23a: "Чи була завдана шкода майну або здоров'ю?",
            question24: "Наявність допуску до роботи оператором навантажувача",
            question25: "Виконання робіт, пов'язаних з обслуговуванням та керуванням навантажувачем",
            question26: "Нещасний випадок під час обслуговування або керування навантажувачем",
            question26a: "Чи була завдана шкода майну або здоров'ю?",
          },
          questionAnswers: {
            question5a: "Монотонна робота",
            question5b: "Постійний великий потік інформації",
            question5c: "Високий рівень шуму",
            question5d: "Робота з високим психологічним навантаженням (відповідальність та прийняття рішень)",
            question5e: "Іонізуюче випромінювання",
            question5f: "Хімічні фактори",
            question5g: "Інші",

            question13a: "менше 3 000 км на рік",
            question13b: "між 3 000 – 15 000 км на рік",
            question13c: "між 15 000 - 70 000 км на рік",
            question13d: "більше 70 000 км на рік",
            question13e: "важко сказати",

            question14a: "менше 5 годин на тиждень",
            question14b: "між 5 - 15 годин на тиждень",
            question14c: "між 15 - 40 годин на тиждень",
            question14d: "більше 40 годин на тиждень",
            question14e: "важко сказати",

            question18a: "як винуватець/співвинуватець",
            question18b: "як потерпілий",
            question18c: "не застосовується",

            question19a: "як винуватець/співвинуватець",
            question19b: "як потерпілий",
            question19c: "не застосовується",
          },
        },
        licensedActivity: {
          questionTexts: {
            question1: "Загальний стаж роботи в установі, що займається ліцензованою діяльністю",
            question2: "Посада/виконувані обов'язки",
            question3: "Стаж роботи на поточній посаді в установі, що займається ліцензованою діяльністю",
            question4a: "Участь у пригоді на робочому місці",
            question4b: "Якими були причини, наслідки пригоди - чи була завдана шкода майну, здоров'ю?",
          },
        },
        detectivesSecurityGuardsAndWeaponPermits: {
          questionTexts: {
            question1: "Основні цілі використання зброї:",
            question1g: "Які?",
            question2: "Чи мало місце коли-небудь застосування зброї або інших засобів прямого примусу?",
            question3: "Як часто застосовувалась зброя за останні 5 років?",
          },
          questionAnswers: {
            question1a: "для професійних цілей (детектив, охоронець)",
            question1b: "для мисливських цілей",
            question1c: "для спортивних цілей",
            question1d: "для особистого користування",
            question1e: "для музейних, пам'ятних, наукових цілей",
            question1f: "інше",
          },
        },
      },
    },
  },
  Language: { pl: "PL", ru: "RU", uk: "UK" },
  SearchEngine: {
    header: "Пошук обстежуваних",
    placeholderMobile: "Пошук",
    placeholderDesktop: "Ім'я, прізвище, ПЕСЕЛ/Ідентифікатор, дата призначення обстеження (РРРР-ММ-ДД)",
    noResults: "Немає результатів пошуку",
    noResultsForFilter: "Не знайдено обстежуваних, що відповідають введеним вимогам.",
    searchForExaminees: "Пошук обстежуваних",
    enterFilter: "Ви можете шукати за ім'ям, прізвищем, номером ПЕСЕЛ/Ідентифікатором\n" +
      "або за датою призначення обстеження \n" +
      "у форматі РРРР-ММ-ДД.",
  },
  AdminSearchEngine: {
    header: "Пошук клієнтів",
    placeholderMobile: "Пошук",
    placeholderDesktop: "назва, ID, адреса, податковий номер, дата створення, дата закінчення дії облікового запису клієнта у форматі РРРР-ММ-ДД, ім'я, прізвище, електронна пошта користувача, дата закінчення дії ліцензії, методологія, тест",
    noResults: "Немає результатів пошуку",
    noResultsForFilter: "Не знайдено клієнтів, що відповідають введеним вимогам.",
    searchForExaminees: "Пошук клієнтів",
    enterFilter: "Ви можете шукати за назвою, ID, адресою, податковим номером, датою створення, датою закінчення дії облікового запису клієнта у форматі РРРР-ММ-ДД, ім'ям, прізвищем, електронною поштою користувача, датою закінчення дії ліцензії у форматі РРРР-ММ-ДД, методологією, тестом.",
  },
  Snackbars: {
    createExamineeSuccess: "Обстежуваного успішно додано",
    editExamineeSuccess: "Обстежуваного успішно відредаговано",
    createPsychLabSuccess: "Обліковий запис клієнта успішно додано",
    editPsychLabSuccess: "Обліковий запис клієнта успішно відредаговано",
    failedRequest: "Виникла проблема: ",
    movingExamineesSuccess: "Успішно переміщено до іншої папки",
    passwordChangeSuccess: "Пароль успішно змінено",
    pinChangeSuccess: "ПІН-код успішно змінено",
    success: "Дію виконано успішно",
    unrecognizedError: "Невизнана помилка, перевірте підключення до інтернету",
    createExaminationSuccess: "Обстеження успішно призначено",
    deleteExaminationSuccess: "Обстеження успішно видалено",
    editPsychUserSuccess: "Користувача успішно відредаговано",
    createPsychUserSuccess: "Користувача успішно додано",
    createPsychLicSuccess: "Ліцензію успішно додано",
    editPsychLicSuccess: "Ліцензію успішно відредаговано",
  },
  UserSettings: {
    header: "Налаштування облікового запису",
    headerChangePassword: "Зміна пароля",
    headerChangePin: "Зміна ПІН-коду",
    setNewPin: "Встановити новий ПІН-код",
    pinInfo: "Введіть ПІН-код, який ви будете використовувати для входу в консоль психолога.",
    headerMobile: "Налаштування",
    facilityInfo: "Інформація про заклад",
    userInfo: "Інформація про користувача",
    passwordChange: "Зміна пароля",
    passwordExpiry: "Термін дії вашого пароля закінчується: ",
    pinChange: "Зміна ПІН-коду",
    infoSubHeaders: {
      labName: "Назва",
      address: "Адреса",
      cin: "Реєстраційний номер",
      tin: "ІПН",
      firstName: "Ім'я",
      lastName: "Прізвище",
      email: "Електронна пошта",
      dateExpire: "Дата закінчення облікового запису",
      licenseNumber: "Номер ліцензії"
    },
    helpdeskMsgUser: "Для зміни інформації про заклад або користувача зателефонуйте за номером:",
    helpdeskMsgLab: "Для зміни інформації про заклад або користувача зателефонуйте за номером:",
  },
  Backend: {
    User_activation_code_has_already_been_used: "Код активації вже використано.",
    User_activation_code_does_not_exist: "Вказаний код активації не існує.",
    User_with_given_id_does_not_exist: "Користувач із вказаним ID не існує.",
    Token_not_recognized: "Токен не розпізнано.",
    Unrecognized_token_Please_regenerate_password_reset_link: "Нерозпізнаний токен, будь ласка, згенеруйте нове посилання для скидання пароля.",
    Invalid_token_Please_regenerate_password_reset_link: "Недійсний токен, будь ласка, згенеруйте нове посилання для скидання пароля.",
    Validation_failed: "Перевірка введених даних не вдалася.",
    Could_not_bind_users_PsychologicalLab_with_the_examinee: "Не вдалося пов'язати заклад користувача з обстежуваним.",
    Could_not_bind_users_PsychologicalLab_with_the_directory: "Не вдалося пов'язати заклад користувача з папкою.",
    The_password_cannot_be_the_same_as_the_previous_one: "Пароль не може бути таким самим, як попередній.",
    Incorrect_credentials: "Невірний пароль.",
    Unrecognized_email: "Адреса електронної пошти не розпізнана або обліковий запис не активовано.",
    Directory_has_related_Examinees: "Неможливо видалити Папку: Папка містить пов'язаних обстежуваних.",
    Unable_to_delete_default_directory: "Неможливо видалити Папку за замовчуванням.",
    The_new_pin_cannot_be_the_same_as_the_previous_one: "Новий ПІН-код не може бути таким самим, як попередній.",
    Directory_with_given_name_already_exists: "Папка з вказаною назвою вже існує.",
    Examination_Card_does_not_have_a_defined_NormSet: "Картка обстеження не має призначеної норми обстеження.",
    Examinee_with_the_given_id_exists_in_this_psychological_lab: "Обстежуваний із вказаним ID вже існує в базі даних.",
    The_Examinee_has_related_Examinations: "Неможливо видалити Обстежуваного: В обстежуваного є пов'язані обстеження.",
    Unrecognized_proxy_error_occurred_while_fetching_legacy_data: "Виникла нерозпізнана помилка при отриманні архівних даних.",
    Legacy_data_instance_not_found: "Архівні дані не знайдено.",
    Legacy_data_validation_failed: "Помилка перевірки архівних даних.",
    Unable_to_parse_Examination_Aim: "Помилка перевірки Мети Обстеження.",
    Unable_to_parse_Occupational_Medicine_Basic_Info_data: "Помилка перевірки даних Медицини Праці.",
    Ongoing_examination: "Неможливо видалити Обстеження. Обстеження в процесі.",
  },
  Onboarding: {
    errorText: "Під час встановлення пароля сталася помилка, будь ласка, спробуйте пізніше.",
    successTitle: "Пароль та ПІН-код встановлено",
    success: "Ваші пароль та ПІН-код збережено. Тепер ви можете вперше увійти в додаток, використовуючи свою електронну адресу та пароль.",
    setNewPasswordInfo: "Введіть пароль, який ви будете використовувати для входу в систему.",
    setPasswordFormLabel: "Встановити пароль",
    setPinInfo: "Введіть ПІН-код, який ви будете використовувати для входу в консоль психолога.",
    setPinFormLabel: "Встановіть свій ПІН-код",
  },
  ResetPassword: {
    formHeader: "Скидання Пароля",
    resetInfo: "Введіть електронну адресу вашого облікового запису, щоб отримати посилання для скидання пароля.",
    resetEmailSentPt1: "На електронну адресу: ",
    resetEmailSentPt2: " надіслано повідомлення з інструкціями щодо процесу скидання пароля.",
    errorText: "Під час скидання пароля сталася помилка, будь ласка, спробуйте пізніше.",
    successTitle: "Пароль Збережено",
    success: "Ваш пароль було змінено. Тепер ви можете увійти в додаток, використовуючи свою електронну адресу та новий пароль.",
    setNewPassword: "Встановити новий пароль",
    setNewPasswordInfo: "Введіть пароль, який ви будете використовувати для входу в систему.",
  },
  FormLabels: {
    email: "Електронна пошта",
    password: "Пароль",
    currentPassword: "Поточний пароль",
    newPassword: "Новий пароль",
    pin: "ПІН-код",
  },
  FormValidationErrors: {
    required: "Це поле є обов'язковим",
    email: "Введене значення не є адресою електронної пошти",
  },
  Login: {
    header: "ВХІД",
    adminHeader: "ПАНЕЛЬ АДМІНІСТРАТОРА",
    error: "Невірна електронна пошта або пароль.",
    warning: "Увімкнено CAPS LOCK",
    reset: "Скинути пароль",
    Instance_configured_incorrectly: "Помилка конфігурації системи. Повідомте про проблему в технічну підтримку.",
    No_recaptcha_token_provided: "Помилка recaptcha. Повідомте про проблему в технічну підтримку.",
    Validation_failed: "Невірна електронна пошта або пароль.",
    Captcha_error: "Помилка при заповненні captcha. Будь ласка, спробуйте знову.",
    Access_denied: "Доступ заборонено"
  },
  Directory: {
    defaultFolderName: "Папка за замовчуванням",
    header: "Картотека обстежуваних",
    popup: {
      create: "Введіть назву папки",
      edit: "Змінити назву папки",
    },
    emptyState: {
      header: "У вибраній папці немає обстежуваних",
      info: "Щоб побачити список обстежуваних у папці, додайте хоча б одного обстежуваного",
    },
    labels: {
      name: "Назва папки",
      owner: "Власник",
      examineeCount: "Люди",
    },
    kebabMenu: {
      addExaminee: "Додати обстежуваного",
      addDirectory: "Додати папку",
      edit: "Змінити назву папки",
      delete: "Видалити папку",
    },
    popups: {
      delete: {
        confirmationHeader: "Ви впевнені, що хочете видалити папку?",
        confirmationText: "Після видалення папку неможливо буде відновити.",
      },
    },
  },
  DirectoryPreview: {
    headerPopup: "Перемістити обстежуваних",
    labels: {
      firstName: "Ім'я",
      lastName: "Прізвище",
      peselSlashIdentifier: "ІПН / Ідентифікатор",
      lastModification: "Остання активність",
    },
    kebabMenu: {
      edit: "Редагувати дані обстежуваного",
      move: "Перемістити обстежуваного",
      delete: "Видалити обстежуваного",
    },
    breadcrumbs: "Картотека обстежуваних",
  },
  Pagination: {
    outOf: "з",
  },
  PasswordExpired: {
    popup: {
      header: "Термін дії пароля закінчився",
      text: "Вас буде перенаправлено на процес скидання пароля для встановлення нового.",
    },
    form: {
      header: "Встановити новий пароль",
      text: "Введіть пароль, який ви будете використовувати для входу в систему.",
    },
    success: {
      header: "Пароль збережено",
      text: "Ваш пароль було змінено. Тепер ви можете перейти до додатку.",
    },
  },
  DocumentationManagement: {
    snackbar: {
      save: "Документ успішно збережено",
    },
    names: {
      ExaminationCard: "Картка обстеження",
      ExaminationCardTEST2WORK: "Картка обстеження TEST2WORK",
      ExaminationCardTEST2SAFE: "Картка обстеження TEST2SAFE",
      ExaminationCardTEST2ARMS: "Картка обстеження TEST2ARMS",
      ExaminationCardTEST2DRIVE: "Картка обстеження TEST2DRIVE",
      PsychologicalConsultation: "Психологічна консультація",
      PsychologicalConsultationTEST2WORK: "Психологічна консультація",
      PsychologicalConsultationTEST2SAFE: "Психологічна консультація",
      PsychologicalConsultationTEST2ARMS: "Психологічна консультація",
      PsychologicalConsultationTEST2DRIVE: "Психологічна консультація",
      PsychologicalOpinion: "Психологічний висновок",
      PsychologicalOpinionTEST2WORK: "Психологічний висновок",
      PsychologicalOpinionTEST2SAFE: "Психологічний висновок - детективи",
      PsychologicalOpinionTEST2ARMS: "Психологічний висновок",
      PsychologicalOpinionTEST2DRIVE: "Психологічний висновок",
      DriverJudgment: "Висновок на керування транспортними засобами",
      DriverJudgmentTEST2WORK: "Висновок на керування транспортними засобами",
      DriverJudgmentTEST2SAFE: "Висновок на керування транспортними засобами",
      DriverJudgmentTEST2ARMS: "Висновок на керування транспортними засобами",
      DriverJudgmentTEST2DRIVE: "Висновок на керування транспортними засобами",
      DrivingExaminerJudgment: "Висновок екзаменатора з водіння",
      DrivingExaminerJudgmentTEST2WORK: "Висновок екзаменатора з водіння",
      DrivingExaminerJudgmentTEST2SAFE: "Висновок екзаменатора з водіння",
      DrivingExaminerJudgmentTEST2ARMS: "Висновок екзаменатора з водіння",
      DrivingExaminerJudgmentTEST2DRIVE: "Висновок екзаменатора з водіння",
      DrivingInstructorJudgment: "Висновок інструктора з водіння",
      DrivingInstructorJudgmentTEST2WORK: "Висновок інструктора з водіння",
      DrivingInstructorJudgmentTEST2SAFE: "Висновок інструктора з водіння",
      DrivingInstructorJudgmentTEST2ARMS: "Висновок інструктора з водіння",
      DrivingInstructorJudgmentTEST2DRIVE: "Висновок інструктора з водіння",
      DrivingTechniqueInstructorJudgment: "Висновок інструктора з техніки водіння",
      EmergencyVehicleDriverJudgment: "Висновок на керування спецтранспортом",
      EmergencyVehicleDriverJudgmentTEST2WORK: "Висновок на керування спецтранспортом",
      EmergencyVehicleDriverJudgmentTEST2SAFE: "Висновок на керування спецтранспортом",
      EmergencyVehicleDriverJudgmentTEST2ARMS: "Висновок на керування спецтранспортом",
      EmergencyVehicleDriverJudgmentTEST2DRIVE: "Висновок на керування спецтранспортом",
      FirearmLicenseJudgment: "Висновок на право володіння зброєю",
      FirearmLicenseJudgmentTEST2WORK: "Висновок на право володіння зброєю",
      FirearmLicenseJudgmentTEST2SAFE: "Висновок на право володіння зброєю",
      FirearmLicenseJudgmentTEST2ARMS: "Висновок на право володіння зброєю",
      FirearmLicenseJudgmentTEST2DRIVE: "Висновок на право володіння зброєю",
      MilitaryExplosivesJudgment: "Висновок на поліцейську/військову зброю та матеріали",
      MilitaryExplosivesJudgmentTEST2WORK: "Висновок на поліцейську/військову зброю та матеріали",
      MilitaryExplosivesJudgmentTEST2SAFE: "Висновок на поліцейську/військову зброю та матеріали",
      MilitaryExplosivesJudgmentTEST2ARMS: "Висновок на поліцейську/військову зброю та матеріали",
      MilitaryExplosivesJudgmentTEST2DRIVE: "Висновок на поліцейську/військову зброю та матеріали",
      OccupationalMedicineJudgmentTEST2WORK: "Висновок з медицини праці",
      OccupationalMedicineJudgmentTEST2SAFE: "Висновок з медицини праці",
      OccupationalMedicineJudgmentTEST2ARMS: "Висновок з медицини праці",
      OccupationalMedicineJudgmentTEST2DRIVE: "Висновок з медицини праці",
      PhysicalProtectionJudgment: "Висновок на ліцензію охоронця",
      PhysicalProtectionJudgmentTEST2WORK: "Висновок на ліцензію охоронця",
      PhysicalProtectionJudgmentTEST2SAFE: "Висновок - працівник фізичної охорони",
      PhysicalProtectionJudgmentTEST2ARMS: "Висновок на ліцензію охоронця",
      PhysicalProtectionJudgmentTEST2DRIVE: "Висновок на ліцензію охоронця",
      ProfessionalDriverJudgment: "Висновок на посаду водія",
      ProfessionalDriverJudgmentTEST2WORK: "Висновок на посаду водія",
      ProfessionalDriverJudgmentTEST2SAFE: "Висновок на посаду водія",
      ProfessionalDriverJudgmentTEST2ARMS: "Висновок на посаду водія",
      ProfessionalDriverJudgmentTEST2DRIVE: "Висновок на посаду водія"
    },
    emptyState: {
      header: "Обстеження не завершено",
    },
    report: {
      details: {
        assignDate: "Дата призначення обстеження",
        endDate: "Дата завершення обстеження",
        firstName: "Ім'я",
        PESEL: "ІПН",
        identificationNumber: "Ідентифікатор",
        idExamination: "ID обстеження",
        lastName: "Прізвище",
        name: "Назва обстеження",
        status: "Статус обстеження",
      },
      statusExamination: {
        skip: "Деякі тести були пропущені або перервані",
      },
      header: {
        language: "Виберіть мову звіту",
        standard: "Виберіть стандарт",
        recipient: "Виберіть отримувача",
        interval: "Виберіть довірчий інтервал",
        reportInterpretation: "Інтерпретаційний звіт",
        reportDiagnostic: "Діагностичний звіт",
      },
    },
    consultation: {
      breadcrumbs: "Психологічна консультація",
      examineeData: "Дані обстежуваного",
      statement: "засвідчую",
    },
    opinion: {
      breadcrumbs: "Психологічний висновок",
      breadcrumbsAppeal: "Апеляційний психологічний висновок",
      examineeData: "Дані обстежуваного",
      statement: "НА ПІДСТАВІ ПРОВЕДЕНОГО ПСИХОЛОГІЧНОГО ОБСТЕЖЕННЯ ЗАСВІДЧУЮ",
    },
    judgment: {
      isAppealTitle: "АПЕЛЯЦІЙНИЙ ВИСНОВОК",
      appeal: "На цей висновок може бути подана апеляція до",
      appealDate: "Протягом",
      appealDay: "Протягом",
      appealDay2: "днів з моменту отримання",
      appealDayTlt: "Кількість днів",
      drivingLicense: "Щодо посвідчення водія категорії",
      judgmentNumber: "Психологічний висновок №",
      judgmentNumberAppeal: "Психологічний висновок №",
      legalBais: "Правова підстава обстеження",
      statement: "Засвідчую*",
      occupationalMedicineStatement: "Встановлюю*",
      statementThat: "Засвідчую, що",
      nextLegalBasis: "Правова підстава наступного обстеження:",
      remarks: "Примітки",
      ART_1: "ст. 39к ч. 3 п. 1",
      ART_2: "ст. 39к ч. 3 п. 2",
      examiner_ART_1: "ст. 34 ч. 5 п. 1",
      examiner_ART_2: "ст. 34 ч. 5 п. 2",
      header: {
        base: "Висновок",
        baseAppeal: "Апеляційний висновок",
        baseAppealFooter: "Цей висновок є остаточним.",
        civilExplosives: "Ліцензована діяльність - для цивільного використання",
        civilExplosivesAppeal: "Ліцензована діяльність - для цивільного використання - апеляція",
        driver: "Керування транспортними засобами",
        emergencyVehicleDriver: "Водій спецтранспорту",
        firearmLicense: "Дозвіл на зброю",
        militaryExplosives: "Ліцензована діяльність - військового або поліцейського призначення",
        physicalProtection: "Працівник охорони",
        profesionalDriver: "Посада водія",
        drivingInstructor: "Інструктор",
        drivingExaminer: "Екзаменатор",
        occupationalMedicine: "Медицина праці"
      },
      emergencyVehicleDriver: {
        true: "відсутні протипоказання до керування спецтранспортом або інкасаторським транспортом",
        false: "наявні протипоказання до керування спецтранспортом або інкасаторським транспортом"
      },
      drivingVehicles: {
        true: "відсутні психологічні протипоказання до керування транспортними засобами",
        false: "наявні психологічні протипоказання до керування транспортними засобами"
      },
      physicalProtection: {
        true: "має здатність виконувати обов'язки працівника фізичної охорони",
        false: "не має здатності виконувати обов'язки працівника фізичної охорони"
      },
      civilExplosives: {
        true: "не виявляє порушень психологічного функціонування і тому може здійснювати діяльність з придбання та зберігання вибухових матеріалів цивільного призначення",
        false: "виявляє порушення психологічного функціонування і тому не може здійснювати діяльність з придбання та зберігання вибухових матеріалів цивільного призначення"
      },
      drivingInstructor: {
        true: "відсутні протипоказання до виконання обов'язків інструктора/екзаменатора/інструктора з техніки водіння",
        false: "наявні протипоказання до виконання обов'язків інструктора/екзаменатора/інструктора з техніки водіння"
      },
      drivingExaminer: {
        true: "відсутні протипоказання до виконання обов'язків інструктора/екзаменатора/інструктора з техніки водіння",
        false: "наявні протипоказання до виконання обов'язків інструктора/екзаменатора/інструктора з техніки водіння"
      },
      militaryExplosives: {
        true: "не виявляє істотних порушень психологічного функціонування, що перешкоджають здійсненню або керуванню господарською діяльністю у сфері виробництва та обігу вибухових матеріалів, зброї, боєприпасів та виробів і технологій військового або поліцейського призначення",
        false: "виявляє істотні порушення психологічного функціонування, що перешкоджають здійсненню або керуванню господарською діяльністю у сфері виробництва та обігу вибухових матеріалів, зброї, боєприпасів та виробів і технологій військового або поліцейського призначення"
      },
      professionalDriver: {
        true: "відсутні психологічні протипоказання до виконання роботи на посаді водія",
        false: "наявні психологічні протипоказання до виконання роботи на посаді водія"
      },
      firearmLicense: {
        true: "не належить до осіб, зазначених у ст. 15 ч. 1 п. 3 закону від 21 травня 1999 р. про зброю та боєприпаси і може володіти зброєю",
        false: "належить до осіб, зазначених у ст. 15 ч. 1 п. 3 закону від 21 травня 1999 р. про зброю та боєприпаси і не може володіти зброєю"
      },
      occupationalMedicine: {
        true: "відсутні психологічні протипоказання до",
        false: "наявні психологічні протипоказання до",
        justification: "Обґрунтування"
      },
    },
    ExaminationCard: {
      header: "Картка обстеження",
      headerTEST2WORK: "Картка обстеження TEST2WORK",
      headerTEST2SAFE: "Картка обстеження TEST2SAFE",
      headerTEST2ARMS: "Картка обстеження TEST2ARMS",
      headerTEST2DRIVE: "Картка обстеження TEST2DRIVE",
      fetchUpdated: "Оновити даними опитування та результатами",
      tooltipFetchUpdated: "Ця дія видалить усі незбережені зміни з Ходу обстеження",
      tooltipFetchUpdatedDisabled: "Для розблокування дії виберіть стандарт обстеження.",
      tabs: {
        examinee: "Обстежуваний",
        courseOfExamination: "Хід обстеження",
        results: "Результати психологічного обстеження"
      },
      examinee: {
        chosenNormSet: "Стандарт, що застосовується при обстеженні",
        examinationDate: "Дата обстеження",
        previousExaminationDate: "Дата попереднього обстеження",
        aim: "Мета обстеження",
        entityDirectingToExamination: "Направляюча організація",
        t2dAim: {
          drivingVehiclesAim: "Визначення відсутності або наявності протипоказань до керування транспортними засобами.",
          professionalDriverAim: "Визначення відсутності або наявності протипоказань до роботи на посаді водія.",
          emergencyDriverAim: "Визначення відсутності або наявності протипоказань до керування спецтранспортом або інкасаторським транспортом.",
          drivingInstructorAim: "Визначення відсутності або наявності протипоказань до виконання обов'язків інструктора/екзаменатора/інструктора з техніки водіння."
        }
      },
      examinationCourse: {
        forcedUpdateHeader: "Оновити Картку Обстеження даними з Опитування та Результатами",
        forcedUpdateBody: "Для розблокування форми заповніть Опитування для Обстеження, виберіть стандарт у вкладці Обстежуваний та оновіть Картку Обстеження даними з Опитування та Результатами.",
        header: "Дані прямого опитування та спостереження за обстежуваним",
        methodology: "Методи обстеження та результати",
        recommendations: "Рекомендації",
        conclusions: "Висновки",
        conclusionsRecomendation: "Висновки та рекомендації",
        careerHistory: "Історія роботи",
        examinationResultsHeader: "Назва тесту та результати (в рамках рекомендованої методики)",
        examinationExtendedResultsHeader: "Назва тесту та додаткові результати",
        cognitiveResultsHeader: "Оцінка та опис інтелектуальних здібностей та пізнавальних процесів обстежуваного (в рамках рекомендованої методики)",
        cognitiveExtendedResultsHeader: "Оцінка та опис інтелектуальних здібностей та пізнавальних процесів - додаткові результати",
        personalityResultsHeader: "Оцінка та опис особистості обстежуваного, включаючи функціонування в складних ситуаціях та соціальну зрілість (в рамках рекомендованої методики)",
        personalityExtendedResultsHeader: "Оцінка та опис особистості обстежуваного, включаючи функціонування в складних ситуаціях та соціальну зрілість - додаткові результати",
        psychomotorResultsHeader: "Психомоторні показники (в рамках рекомендованої методики)",
        psychomotorExtendedResultsHeader: "Психомоторні показники - додаткові результати",
        occupationalMedicineAdditionalInfo: "Додаткова інформація - Медицина праці",
        examinationResultsT2WHeader: "Рекомендована методика",
        examinationExtendedResultsT2WHeader: "Додатково",
        addExaminationExtendedResults: "Додати додатковий результат",
        deletePopup: {
          headerText: "Ви впевнені, що хочете видалити цей результат?",
          contentText: "Після видалення результату можна відновити його початковий вміст шляхом оновлення даних",
          confirmationText: "Так, я хочу видалити результат"
        }
      },
      judgment: {
        result: "Результат психологічного обстеження",
        sent: "Висновок/копію висновку надіслано, якщо застосовно",
        judgment: "Зміст висновку",
        justification: "Обґрунтування висновку",
        justificationText: "Зміст обґрунтування",
        recommendations: "Рекомендації",
        to: "до",
        t2d: {
          lack: "відсутні",
          existing: "наявні",
          questions: {
            drivingVehiclesContraindication: "Протипоказання до керування транспортними засобами.",
            professionalDriverContraindication: "Протипоказання до роботи на посаді водія.",
            emergencyVehicleDriverContraindication: "Протипоказання до керування спецтранспортом або інкасаторським транспортом.",
            drivingInstructorContraindication: "Протипоказання до виконання обов'язків інструктора/екзаменатора/інструктора з техніки водіння."
          }
        }
      },
    },
  },
};
