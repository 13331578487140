import * as styles from "../forms.module.scss";
import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import FormInputText from "../Inputs/FormInputText";
import { schemaPsychologicalLabUser } from "../schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { createPsychUser } from "../../../redux/actions/adminPanel/psychologicalUserActions";
import { I18n } from "react-redux-i18n";
import { handleNavigateToPreviousPage } from "../../../utils/paths";
import { CreatePsychUserData } from "../types";
import { selectPsychUserCUDError, selectPsychUserCUDSucceeded } from "../../../redux/selectors/adminPanel/psychologicalUserSelectors";
import { useIsFirstRender } from "usehooks-ts";
import { dispatchNotification } from "../../../utils/redux";
import { IsObjectEmpty } from "../../../utils";
import FormInputMultiRadio from "../Inputs/FormInputMultiRadio";
import { selectSelectedPsychLabData } from "../../../redux/selectors/adminPanel/selectedPsychLabSelectors";
import FormInputDropdown from "../Inputs/FormInputDropdown";
import { langFormOptions, translateFormOption } from "../utils";

const FormAddPsychUser: FC = () => {
  const dispatch = useDispatch();
  const isFirst = useIsFirstRender();
  const createPsychUserCUDSuccess = useSelector(selectPsychUserCUDSucceeded);
  const createPsychUserCUDError = useSelector(selectPsychUserCUDError);
  const psychLab = useSelector(selectSelectedPsychLabData);
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm({
    shouldUnregister: false,
    reValidateMode: "onChange",
    resolver: yupResolver(schemaPsychologicalLabUser),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      isActive: true,
      isStaff: true,
      isSuperuser: false,
      id: 0,
      lang: "pl-PL",
    },
  });

  const onSubmit = (data: CreatePsychUserData) => {
    const userData = { labId: psychLab?.id, ...data };
    dispatch(createPsychUser(userData));
  };

  useEffect(() => {
    if (!isFirst && createPsychUserCUDSuccess) {
      void handleNavigateToPreviousPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPsychUserCUDSuccess, createPsychUserCUDError]);

  useEffect(() => {
    if (!isFirst && !IsObjectEmpty(errors)) {
      (Object.keys(dirtyFields).length > 1) &&
        dispatchNotification("error", I18n.t("Forms.adminSnackbar.required"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, isFirst]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formWideContainer}>
        <div className={styles.fieldsContainer}>
          <FormInputText
            longInput="maxWidth"
            control={control}
            name="firstName"
            label={I18n.t("Forms.adminFields.firstName")}
            error={!!errors.firstName}
            required
          />
          <FormInputText
            longInput="maxWidth"
            control={control}
            name="lastName"
            label={I18n.t("Forms.adminFields.lastName")}
            error={!!errors.lastName}
            required
          />
          <FormInputText
            longInput="maxWidth"
            control={control}
            name="email"
            label={I18n.t("Forms.adminFields.email")}
            error={!!errors.email}
            required
          />
          <FormInputDropdown
            control={control}
            name="lang"
            label={I18n.t("Forms.fields.lang")}
            options={translateFormOption(langFormOptions)}
            error={"lang" in errors}
            required
            setValue={setValue}
            defaultValue={"pl-PL"}
          />
          <div className={styles.radioFieldContainer}>
            <FormInputMultiRadio
              label={I18n.t("Forms.adminFields.isActive")}
              required
              name="isActive"
              row
              setValue={setValue}
              control={control}
              options={[
                { label: I18n.t("Forms.adminOptions.status.active"), value: true },
                { label: I18n.t("Forms.adminOptions.status.disabled"), value: false },
              ]}
            />
          </div>
          <div className={styles.radioFieldContainer}>
            <FormInputMultiRadio
              label={I18n.t("Forms.adminFields.isStaff")}
              required
              name="isStaff"
              row
              setValue={setValue}
              control={control}
              options={[
                { label: I18n.t("Forms.adminOptions.admin.user"), value: true },
                { label: I18n.t("Forms.adminOptions.admin.baseuser"), value: false },
              ]}
            />
          </div>
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <SecondaryButton
          text={I18n.t("Buttons.cancel")}
          event={handleNavigateToPreviousPage}
        />
        <PrimaryButton text={I18n.t("Buttons.save")} isSubmit />
      </div>
    </form>
  );
};

export default FormAddPsychUser;
